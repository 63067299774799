<template>
	<nav class="hidden sm:flex border-b border-gray-200 bg-white" aria-label="BreadCrumb">
		<ol role="list" class="mx-auto flex w-full max-w-screen-xl space-x-4 px-4 sm:px-6 lg:px-8">
			<li class="flex">
				<div class="flex items-center">
					<router-link to="/" class="text-gray-400 hover:text-gray-500">
						<HomeIcon class="h-5 w-5 flex-shrink-0" aria-hidden="true" />
						<span class="sr-only">Home</span>
					</router-link>
				</div>
			</li>
			<li v-for="page in pages" :key="page.name" class="flex">
				<div class="flex items-center">
					<svg class="h-full w-6 flex-shrink-0 text-gray-200" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" aria-hidden="true">
						<path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
					</svg>
					<router-link :to="page.href" class="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700" :aria-current="page.current ? 'page' : undefined">{{ page.name }}</router-link>
				</div>
			</li>
		</ol>
	</nav>


	<component :is="'script'" type="application/ld+json">
		{{ displayCode(pages) }}
	</component>
</template>

<script setup>
import { HomeIcon } from '@heroicons/vue/20/solid'

</script>


<script>
export default {
  name: 'BreadCrumb',
  props: {
    pages: Array,
  },
	methods: {
		displayCode(pages) {
			let code = {
				"@context": "https://schema.org",
				"@type": "BreadcrumbList",
				"itemListElement": []
			};

			let pocitadlo = 0;

			pages.forEach(stranka => {
				pocitadlo++;

				code.itemListElement.push({
					"@type": "ListItem",
					"position": pocitadlo,
					"name": stranka.name,
					"item": `https://petgotlost.com${stranka.href}`
				});
			});
			return code;	
		}
	}


}
</script>