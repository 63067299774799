<template>
	<MainHeader />


	<div class="mx-auto w-fit my-16" v-if="typeof pet.petName === 'undefined'" >
			<div role="status">
					<svg aria-hidden="true" class="inline w-24 h-24 mr-2 text-gray-200 animate-spin fill-yellow-400" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
							<path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
					</svg>
					<span class="sr-only">Loading...</span>
			</div>
	</div>

	<div v-if="typeof pet.petName !== 'undefined'">
		<BreadCrumb :pages="[
			{ name: 'Lost Pets', href: '/lost-pets', current: false },
			{ name: pet.petName + ' ' + new Date(pet.createdAt).toLocaleDateString('cs-Cz', {day: 'numeric', month: 'short', year: 'numeric'}), href: '/pet/' + pet.uid, current: true },
		]"/>


		

		<div class="bg-stone-50 py-8 pb-24">

			<div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 py-4 rounded-lg">
				<div class="md:flex md:items-center md:justify-between">
					<div class="min-w-0 flex-1">
						<h2 class="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">Lost pet {{ pet.petName }}</h2>
						<p class="text-gray-500 mt-4">Registered in the lost animal database {{ new Date(pet.createdAt).toLocaleDateString('cs-Cz', {
							day: 'numeric', month: 'short', year: 'numeric'
						}) }}</p>
					</div>
					<div class="-ml-3 mt-4 sm:flex md:ml-4 md:mt-0">



						<button type="button" @click="changeEditMode" v-if="!editMode" class="mb-3 md:mb-0 ml-3 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">Edit info</button>
						<button type="button" @click="changeEditMode" v-if="editMode" class="mb-3 md:mb-0 ml-3 inline-flex items-center rounded-md bg-orange-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600">Save</button>
						<button type="button" @click="removePet" v-if="editMode" class="mb-3 md:mb-0 ml-3 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">Delete listing</button>
						<!-- <button type="button" @click="changePetStatusPrompt" v-if="editMode" class="mb-3 md:mb-0 ml-3 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">Změnit status</button> -->
						<button type="button" v-if="!pet.paid && !editMode" @click="campaignStart" class="mb-3 md:mb-0 ml-3 inline-flex items-center rounded-md bg-orange-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600">Start a campaign</button>
						<button type="button" v-if="!pet.paid && editMode" @click="campaignStart" class="mb-3 md:mb-0 ml-3 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">Start a campaign</button>


					</div>
				</div>
			</div>

			<div class="block md:flex items-start mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 mt-12">

				<div class="order-2 mb-12 md:ml-8 lg:ml-20 " >
					<img :src="src" class="aspect-1 w-full max-w-sm  rounded-xl">

					<button v-if="editMode" @click="cropImage" class="mt-6 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">Change image</button>
				</div>

				<div class="overflow-hidden max-w-md lg:max-w-none bg-white shadow sm:rounded-xl">
					<div class="border-t border-gray-100">
						<dl class="divide-y divide-gray-100">
							<div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
								<dt class="text-sm font-medium text-gray-900">Pet's name</dt>
								<input v-if="editMode" v-model="pet.petName" type="text" name="petName" id="petName" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6">
								<dd v-if="!editMode" class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">	
									{{pet.petName}}
								</dd>
							</div>

							<div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
								<dt class="text-sm font-medium text-gray-900">Status</dt>
								<select v-if="editMode" name="status" id="status" v-model="pet.status" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:max-w-xs sm:text-sm sm:leading-6">
										<option value="">Choose</option>
										<option value="lost">Lost</option>
										<option value="found">Found</option>
										<option value="deceased">Died</option>
								</select>
								<dd v-if="!editMode" class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">	
									{{pet.status === "lost" ? "Lost" : ''}}
									{{pet.status === "found" ? "Found" : ''}}
									{{pet.status === "deceased" ? "Died" : ''}}
								</dd>
							</div>

							<div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
								<dt class="text-sm font-medium text-gray-900">Date of disappearance</dt>
								<input v-if="editMode" v-model="pet.petTime" type="text" name="petTime" id="petTime" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6">
								<dd v-if="!editMode" class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">	
									{{pet.petTime}}
								</dd>
							</div>

							<div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
								<dt class="text-sm font-medium text-gray-900">Last known location</dt>
								<input v-if="editMode" v-model="pet.petPlace" type="text" name="petPlace" id="petPlace" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6">
								<dd v-if="!editMode" class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">	
									{{pet.petPlace}}
								</dd>
							</div>


<!-- 
Yorkshire and The Humber
South West
Wales
Northern Ireland
East Midlands
North West
Scotland
East of England
South East
London
West Midlands
North East -->

							<div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
								<dt class="text-sm font-medium text-gray-900">Region</dt>

								<select v-if="editMode" v-model="pet.petRegion" id="petRegion" name="petRegion" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:max-w-xs sm:text-sm sm:leading-6">
									<option value="">Choose an option</option>
									<option value="Yorkshire and The Humber">Yorkshire and The Humber</option>
									<option value="South West">South West</option>
									<option value="Wales">Wales</option>
									<option value="Northern Ireland">Northern Ireland</option>
									<option value="East Midlands">East Midlands</option>
									<option value="North West">North West</option>
									<option value="Scotland">Scotland</option>
									<option value="East of England">East of England</option>
									<option value="South East">South East</option>
									<option value="London">London</option>
									<option value="West Midlands">West Midlands</option>
									<option value="North East">North East</option>
								</select>

								<dd v-if="!editMode" class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">	
									{{pet.petRegion}}
								</dd>
							</div>

							<div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
								<dt class="text-sm font-medium text-gray-900">Reward</dt>
								<input v-if="editMode" v-model="pet.petReward" type="text" name="petReward" id="petReward" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6">
								<dd v-if="!editMode" class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">	
									{{pet.petReward}}
								</dd>
							</div>

							<div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
								<dt class="text-sm font-medium text-gray-900">Sex</dt>
								<select v-if="editMode" v-model="pet.petSex" id="petSex" name="petSex" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:max-w-xs sm:text-sm sm:leading-6">
									<option value="">Choose</option>
									<option value="Male">Male</option>
									<option value="Female">Female</option>
								</select>
								<dd v-if="!editMode" class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">	
									{{pet.petSex}}
								</dd>
							</div>

							<div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
								<dt class="text-sm font-medium text-gray-900">Age</dt>
								<input v-if="editMode" v-model="pet.petAge" type="text" name="petAge" id="petAge" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6">
								<dd v-if="!editMode" class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">	
									{{pet.petAge}}
								</dd>
							</div>

							<div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
								<dt class="text-sm font-medium text-gray-900">Colour</dt>
								<input v-if="editMode" v-model="pet.petColor" type="text" name="petColor" id="petColor" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6">
								<dd v-if="!editMode" class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">	
									{{pet.petColor}}
								</dd>
							</div>

							<div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
								<dt class="text-sm font-medium text-gray-900">Size</dt>
								<select v-if="editMode" id="petSize" name="petSize" v-model="pet.petSize" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:max-w-xs sm:text-sm sm:leading-6">
									<option value="">Choose an option</option>
									<option value="Small under 9kg">Small under 9kg</option>
									<option value="Medium 10-18kg">Medium 10-18kg</option>
									<option value="Big >18kg">Big >18kg</option>
								</select>
								<dd v-if="!editMode" class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">	
									{{pet.petSize}}
								</dd>
							</div>

							<div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
								<dt class="text-sm font-medium text-gray-900">Breed</dt>
								<input v-if="editMode" v-model="pet.petBreed" type="text" name="petBreed" id="petBreed" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6">
								<dd v-if="!editMode" class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">	
									{{pet.petBreed}}
								</dd>
							</div>

							<div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
								<dt class="text-sm font-medium text-gray-900">Description</dt>
								<textarea v-if="editMode" id="petDescription" v-model="pet.petDescription" name="petDescription" rows="3" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6" />

								<dd v-if="!editMode" class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">	
									{{pet.petDescription}}
								</dd>
							</div>

							<div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
								<dt class="text-sm font-medium text-gray-900">Phone number</dt>
								<input v-if="editMode" v-model="pet.userPhone" type="text" name="userPhone" id="userPhone" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6">
								<dd v-if="!editMode" class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">	
									{{pet.userPhone}}
								</dd>
							</div>
						</dl>
					</div>
				</div>

			</div>
	</div>


		<teleport to='body'>
				<PopupWindow v-if="!pet">
					<div class="block">
						<div class="mb-6 font-semibold">Sorry, but this pet doesn't exist. Please try again or contact us on +44 7520 668505.</div>
						<div class="flex">
							<PetButton class="mr-3" text="Close" @click="error = false" />
							<router-link to="/">
								<PetButton text="Return home" />
							</router-link>
						</div>
					</div>
				</PopupWindow>
		</teleport>
		<teleport to="body">
		

				<PopupWindow v-if="cropper">
					<PetCropper @image-cropped="getCroppedImg" @crop-cancel="cropper = false" />
				</PopupWindow>

		</teleport>
		<teleport to="body">
				<PopupWindow v-if="error">
					<div class="block">
						<div class="mb-6 font-semibold">We apologize, but there's been an error. Please try again or contact us on +44 7520 668505.</div>
						<div class="flex">
							<PetButton class="mr-3" text="Close" @click="error = false" />
							<router-link to="/">
								<PetButton text="Return home" />
							</router-link>
						</div>
					</div>
				</PopupWindow>
		</teleport>


		<teleport to="body">
			<TransitionRoot as="template" :show="openModal">
				<Dialog as="div" class="relative z-50 " @close="openModal = false">
					<TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
						<div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
					</TransitionChild>

					<div class="fixed inset-0 z-10 overflow-y-auto">
						<div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
							<TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
								<DialogPanel class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
									<form @submit.prevent="formSubmit" class="">
										<div>
											<div class="text-center sm:mt-5">
												<DialogTitle as="h3" class="text-base font-semibold leading-6 text-gray-900">Please enter password</DialogTitle>
												<div class="mt-2">
													<p class="text-sm mb-4 text-gray-500">Please enter the password you created when you registered your pet.</p>
													<input type="password" ref="pass" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6" />
													<p v-if="wrongPassword" class="text-sm mt-2 text-red-500">Incorrect password</p>
												</div>
											</div>
										</div>
										<div class="mt-5 sm:mt-6">
											<button type="submit" class="cursor-pointer text-md font-semibold leading-6 text-white px-4 py-3 w-full mb-3 bg-gradient-to-r rounded-md from-[#FF9A45] hover:from-[#E68B3E] to-[#FF405B] hover:to-[#E53952] transition-all hover:drop-shadow-sm drop-shadow-md">Confirm</button>
											<button type="button" class="inline-flex w-full justify-center rounded-md bg-gray-200 px-3 py-2 text-sm font-semibold text-black shadow-sm hover:bg-gray-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-400" @click="openModal = false">Go back</button>
										</div>
									</form>
								</DialogPanel>
							</TransitionChild>
						</div>
					</div>
				</Dialog>
			</TransitionRoot>
		</teleport>

		<MainFooter></MainFooter>
	</div>
</template>


<script setup>
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
// import { CheckIcon } from '@heroicons/vue/24/outline'


</script>


<script>
    const axios = require('axios')

    import { toRaw } from '@vue/reactivity'

    const url = process.env.SERVER_URL
    const petUrl = `${url}/api/pets`
    const filter = "?filters[uid][$eq]="
    const populateFilter = "&populate=*"

    import MainHeader from '../components/MainHeader.vue'
    import PetButton from '../components/PetButton.vue'
    import PetCropper from '../components/PetCropper.vue'
    import PopupWindow from '../components/PopupWindow.vue'
    import MainFooter from '@/components/MainFooter.vue'
    import BreadCrumb from '@/components/BreadCrumb.vue'
		import { useSeoMeta } from '@vueuse/head'




    export default {
        name: 'PetView',
        components: {
            MainHeader,
            PetButton,
            PetCropper,
            PopupWindow,
            MainFooter,
            BreadCrumb,
        },
        data() {
            return {
                pet: {},
                petId: String,
                editMode: false,
                petExists: true,
                changeStatus: false,
                cropper: false,
                error: false,
                wrongPassword: false,
                src: '',
								inputtedPassword: this.$refs.pass,
								modalMode: "",
								openModal: false,
            }
        },
        props: {
            uid: String
        },
        methods: {
					formSubmit() {
						// this.v$.$validate()
						// if(!this.v$.$invalid) {
						// 	this.$store.commit('updateForm', this.form)
						// 	this.$router.push('/register');
						// }


						if (this.$refs.pass.value == this.pet.password) {
							this.wrongPassword = false
							this.openModal = false
							this.editMode = true


							if (this.modalMode === "edit") {
								this.editMode = true
							}

							if (this.modalMode === "status") {
								this.changeStatus = true
							}

							if (this.modalMode === "remove") {
								axios.delete(`${petUrl}/${this.petId}`).then(() => {
									this.$router.push('/lost-pets')
								}).catch(err => {
									this.error = true
									console.log(err)
								})
							}

							if (this.modalMode === "campaign") {
								if (this.pet.paid) {
										alert('You already have an active campaign')
								} else {
									const form = toRaw(this.pet)
									form.description = ''
									form.createdAt = ''
									form.publishedAt = ''
									form.updatedAt = ''

									this.$cookies.set('mazel-pet-info', form)
									this.$router.push('/description')
								}
							}



						} else {
							this.wrongPassword = true
						}

						console.log(this.$refs.pass.value);
					},
            async getPet() {
                await axios.get(petUrl + filter + this.uid + populateFilter)
                    .then(res => {
                        if (res.data.data.length == 0) {
                            this.petExists = false
                        }
                        if(res.data.data[0].attributes.petImg.data === null) {
                            res.data.data[0].attributes.petImg.data = {}
                            res.data.data[0].attributes.petImg.data.attributes = {}
                            res.data.data[0].attributes.petImg.data.attributes.url = ""
                        }
                        const rawPet = res.data.data[0].attributes
                        this.pet = rawPet
                        this.petId = res.data.data[0].id
                        this.src = res.data.data[0].attributes.petImg.data.attributes.url

                    })
                    .catch(err => {
                        this.error = true
                        console.log(err)
                    })
            },
            async updatePet() {
                const pet = this.pet
                delete pet.petImg

                const payload = {
                    "data" : pet
                }

                await axios.put(`${petUrl}/${this.petId}`, payload)
                    .then(() => {
                        this.editMode = false
                    })
                    .catch(err => {
                        this.error = true
                        console.error(err)
                    })
            },

            changeEditMode() {
                if(this.editMode) {
                    this.updatePet()
                } else {
									this.modalMode = "edit"
									this.openModal = true
                }
            },

            changePetStatusPrompt() {
							this.modalMode = "status"
							this.openModal = true
            },
						
            changePetStatus() {
                const payload = {
                    data: {
                        status: this.pet.status
                    }
                }

                axios.put(`${petUrl}/${this.petId}`, payload)
                    .then(() => {
                        this.changeStatus = false
                    }).catch(err => {
                        this.error = true
                        console.log(err)
                    })
            },


            removePet() {
							this.modalMode = "remove"
							this.openModal = true
            },


            cropImage() {
                this.cropper = true
            },
            async getCroppedImg(img) {
                this.cropper = false

                this.src = img

                const imgBlob = await fetch(img).then(res => {return res.blob()})

                this.uploadImage(imgBlob)
            },
            async uploadImage(imageFile) {
                try{
                    await axios.delete(`${url}/api/upload/files/${this.pet.petImg.data.id}`)
                        .catch(err => {
                            this.error = true
                            console.log(err)
                        })
                } catch(err) {
                    console.log(err)
                }

                const imageData = new FormData()
                imageData.append('files', imageFile)
                
                const imgId = await axios.post(`${url}/api/upload`, imageData)
                    .then(res => {
                        return res.data[0].id
                    }).catch(err => {
                        this.error = true
                        console.log(err)
                    })

                this.pet.petImg = imgId

                const payload = {
                    data: {
											petImg: imgId
                    }
                }

                await axios.put(`${petUrl}/${this.petId}`, payload)
                    .catch(err => {
                        this.error = true
                        console.log(err)
                    })
            },

						//todo: 
            campaignStart() {
							this.modalMode = "campaign"
							this.openModal = true
            }
        },
        created() {
            this.getPet().then(() => {
							let dateVar = new Date(this.pet.createdAt).toLocaleDateString('cs-Cz', {day: 'numeric', month: 'short', year: 'numeric'});
							let titleVar = this.pet.petName + ' lost: ' + dateVar + ' | PetGotLost.com';

							useSeoMeta ({
								title: titleVar,
								description: 'Database of lost and found animals. Find your pet. ' + this.pet.petDescription,
								ogDescription: 'Database of lost and found animals. Find your pet. ' + this.pet.petDescription,
								ogTitle: titleVar,
								ogImage: this.src,
								twitterCard: 'summary_large_image',
							});
						});
        },
        mounted() {
        }
    }
</script>

