<template>
  <div class="isolate relative">
    <div class="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:px-8 lg:py-32">
      <div class="mx-auto max-w-4xl divide-y divide-stone-900/10">
        <h2 class="text-2xl sm:text-3xl font-bold leading-10 tracking-tight text-stone-900">Frequently Asked Questions</h2>
        <dl class="mt-10 space-y-6 divide-y divide-stone-900/10">
          <Disclosure as="div" v-for="faq in faqs" :key="faq.question" class="pt-6" v-slot="{ open }">
            <dt>
              <DisclosureButton class="flex w-full items-start justify-between text-left text-stone-900">
                <span class="text-base font-semibold leading-7">{{ faq.question }}</span>
                <span class="ml-6 flex h-7 items-center">
                  <PlusSmallIcon v-if="!open" class="h-6 w-6" aria-hidden="true" />
                  <MinusSmallIcon v-else class="h-6 w-6" aria-hidden="true" />
                </span>
              </DisclosureButton>
            </dt>
            <DisclosurePanel as="dd" class="mt-2 pr-12">
              <p class="text-base leading-7 text-stone-600">{{ faq.answer }}</p>
            </DisclosurePanel>
          </Disclosure>
        </dl>
      </div>
    </div>
  </div>


  <component :is="'script'" type="application/ld+json">
    {{ StructuredFaq(faqs) }}
  </component>
</template>

<script setup>
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/vue/24/outline'

const faqs = [
  {
    question: "What is the difference between a traditional post and our service?",
    answer:
      "We use special marketing tools designed for companies that are usually trying to sell a product. However, we use this strategy to find your lost pets. Although we have to pay more money for this tool, it allows us to plan a campaign exactly to our liking, which is not possible with normal posts. For example, it involves reaching people in a specific location that we set up, bigger reach, different demographic filters and so on. However, posts in campaigns look exactly the same as you know them. People can like them, share them among their friends or comment on them.",
  },
  {
    question: "How exactly do the campaigns work?",
    answer:
      "Immediately after the order is placed, we start planning the entire campaign, create the necessary graphics and set up the ads. Everything is then sent to Meta (Facebook) for approval, where it's usually up and running within an hour. At that point, the posts from our search campaign will start appearing on Facebook and Instagram for people in the area of the loss - either classically on their timeline or as stories. People then share these posts amongst their friends, liking, and most importantly, commenting on various clues over the following days. Often, we get to know smaller, but very important details that people wouldn't just call you on their own. We then adapt the campaigns based on this information (e.g. change of location, description, etc.) and keep you informed of any developments on a daily basis.",
  },
  {
    question: "Why is this method so effective?",
    answer:
      "When searching, speed is by far the most important thing. With us, you are able to notify a huge number of people directly in the location of the loss within a few hours. You simply can't do that with various flyers, SMS searches or Facebook groups. The other big advantage is that people will often comment in the comments with smaller, but very important clues that you wouldn't get with a traditional search. We then utilize this data to make real-time changes to your campaign (7 days a week). Thanks to this, we also manage to return 74% of the animals safely home.",
  },
  {
    question: "How do we target the right people?",
    answer:
      "Facebook and Instagram allow us to use their marketing tools to target the exact people who live within a certain radius of losing your pet. Traditionally, we reach all residents within a 10km radius (but it depends on the terrain, size of surrounding towns, etc). So sometimes it's more, sometimes less.",
  },
  {
    question: "What will the specific advert look like?",
    answer:
      "We create ads in the form of Facebook posts, Instagram posts and Instagram Stories. You can see a specific preview after you register for free.",
  },
  {
    question: "What kind of people do we alert? How many people are we reaching? And for how long?",
    answer:
      "Simply all potential rescuers in the area of your pet's loss. Beyond that, we also try to filter the target audience based on various other attributes, mainly to make the search as efficient as possible, especially in larger cities where there are a lot of people around. In such cases, we can reach up to 40,000 people - if necessary. But the number of people reached is generally very dependent on the location of the loss. Our classic campaigns last 7 days as standard, with the first 3 days being the most intense. However, we also offer a cheaper package that lasts only 2 days.",
  },
  {
    question: "How long after I submit my registration will it take to launch the campaign?",
    answer:
      "Immediately after we receive your registration, we will double-check your information to make sure everything is in order. If everything is ok, we'll get to work creating the posts and sending them to Meta (Facebook) for approval. The whole process usually takes around 2 hours, one day at most. A lot depends just on the speed and load of the Facebook platform and the time you send us the registration. The good news is that we operate 7 days a week! So it's not a problem at all to register something on Saturday evening, for example, and by Sunday everything is up and running.",
  },
  {
    question: "How will I know if you have actually launched the campaign?",
    answer:
      "Simple - we'll send you the link to the ad immediately to your email. In addition, we'll keep you updated daily on the campaign's reach and other people's reactions as it progresses.",
  },
  {
    question: "Will you refund my money if our pet is found before the launch?",
    answer:
      "Yes, but you have to be faster than us. As soon as your offer appears in our administration, we start working on it immediately and send the money to Facebook. If you want to cancel something - call us immediately.",
  },
  {
    question: "Why are there fees for your services?",
    answer:
      "As you may have already read, we mainly use paid ads on platforms like Facebook and Instagram. This is completely different from the classic posts on lost animal sites. You have to pay for the reach and the campaign has to be set up by an experienced digital marketer. In addition, your ad must also go through a graphic designer, we communicate with you every day, further edit the campaign, etc.",
  },
]
</script>

<script>
export default {
  name: 'FaqSection',
  components: {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    MinusSmallIcon,
    PlusSmallIcon,
  },
  methods: {
    StructuredFaq(faqs) {
      let code = {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": []
      };

      faqs.forEach((faq) => {
        code.mainEntity.push({
          "@type": "Question",
          "name": faq.question,
          "acceptedAnswer": {
            "@type": "Answer",
            "text": faq.answer
          }
        })
      });

      return JSON.stringify(code, null, 2);
    }
  }
}
</script>