<template>
    <MainHeader />
    <div class="flex flex-row justify-center mt-8">
        <div class="w-[1290px] flex flex-col items-center text-center">
            <div class="font-bold text-4xl mt-10">Please wait...</div>
        </div>
    </div>
    <teleport to="body">
        <PopupWindow v-if="error">
            <div class="block">
                <div class="mb-6 font-semibold">We apologize, but there's been an error. Please try again or contact us on +44 7520 668505.</div>
                <div class="flex">
                    <PetButton class="mr-3" text="Close" @click="error = false" />
                    <router-link to="/">
                        <PetButton text="Return home" />
                    </router-link>
                </div>
            </div>
        </PopupWindow>
    </teleport>
</template>

<script setup>
import { useSeoMeta } from '@vueuse/head'


useSeoMeta ({
	title: 'Please wait...',
	description: 'Please wait...',
	ogDescription: 'Please wait...',
	ogTitle: 'Please wait...',
	ogImage: 'https://petgotlost.com/og.jpg',
	twitterCard: 'summary_large_image',
    robots: "noindex"
});

</script>


<script>
//import BasicButton from '../components/BasicButton.vue'
import MainHeader from '../components/MainHeader.vue'
import PopupWindow from '../components/PopupWindow.vue'
import PetButton from '../components/PetButton.vue'

import axios from 'axios'

const serverAddr = 'http://127.0.0.1:1337'
const url = `${serverAddr}/api/pets`
const filter = "?filters[uid][$eq]="

export default {
    name: "FailureView", 
    components: {
        MainHeader,
        PopupWindow,
        PetButton
    },
    data() {
        return {
            error: false
        }
    },
    props: {
        uid: String
    },
    async mounted() {
        // get pet id
        await this.getPet()
            .then(() => {
                // check if the pet has been paid for already
                if(this.pet.paid) {
                    alert('Your pet has already been paid for')
                    this.$router.push(`/pet/${this.uid}`)
                } else {
                    // store pet info into cookies
                    const form = this.pet
                    form.description = ''
                    this.$cookies.set('mazel-pet-info', {
                        form
                    })
                    this.$router.push('/order')
                }
            })
    },
    methods: {
        async getPet() {
            await axios.get(url + filter + this.uid)
                .then(res => {
                    if (res.data.data.length == 0) {
                        this.petExists = false
                    }
                    const rawPet = res.data.data[0].attributes
                    this.pet = rawPet
                    this.petId = res.data.data[0].id
                })
                .catch(err => {
                    this.error = true
                    console.log(err)
                })
        },
    }
}
</script>