
<template>
    <MainHeader></MainHeader>
    <MainLanding></MainLanding>
    <RecentlySaved></RecentlySaved>
    <AboutUsSection></AboutUsSection>
    <HowItWorks></HowItWorks>
    <MainStats></MainStats>
    <MainTestemonial></MainTestemonial>
    <FaqSection></FaqSection>
    <CtaMain></CtaMain>
    <MainFooter></MainFooter>
    <Footer></Footer>
</template>

<script setup>
    import { useSeoMeta } from '@vueuse/head'

    useSeoMeta ({
        title: 'PetGotLost.com - Find Your Missing Pet, Alert Locals FAST 🐶🐱',
        description: 'Find Your Lost Pet Quickly! Start a FREE alert in just 60 seconds and reach locals on Facebook and Instagram. 1,000+ pets reunited across the UK.',
        ogDescription: 'Find Your Lost Pet Quickly! Start a FREE alert in just 60 seconds and reach locals on Facebook and Instagram. 1,000+ pets reunited across the UK.',
        ogTitle: 'PetGotLost.com - Find Your Missing Pet, Alert Locals FAST 🐶🐱',
        ogImage: 'https://petgotlost.com/og.jpg',
        twitterCard: 'summary_large_image',
    });

</script>

<script>

import MainHeader from '../components/MainHeader.vue'
import MainLanding from '../components/MainLanding.vue'
import MainFooter from '../components/MainFooter.vue'
import CtaMain from '../components/CtaMain.vue'
import AboutUsSection from '../components/AboutUsSection.vue'
import MainTestemonial from '../components/MainTestemonial.vue'
import MainStats from '../components/MainStats.vue'
import HowItWorks from '../components/HowItWorks.vue'
import RecentlySaved from '../components/RecentlySaved.vue'
import FaqSection from '../components/FaqSection.vue'


export default {
    name: 'HomeView',
    components: {
        MainHeader,
        MainLanding,
        MainFooter,
        CtaMain,
        AboutUsSection,
        MainTestemonial,
        MainStats,
        HowItWorks,
        RecentlySaved,
        FaqSection,
    }
}
</script>


<style>
</style>