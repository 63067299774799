<template>
  <div class="bg-stone-50">
    <div class="mx-auto max-w-7xl py-16 sm:px-2 sm:py-24 lg:px-4">
      <div class="mx-auto max-w-2xl px-4 lg:max-w-none">
        <div class="grid grid-cols-1 items-center gap-x-16 gap-y-10 lg:grid-cols-2">
          <div>
            <h2 class="text-3xl lg:text-4xl lg:text-[35px] font-bold tracking-tight text-gray-900">We've helped to find hundreds of lost dogs and cats across the UK</h2>
            <p class="mt-8 text-gray-500">
              As pet owners ourselves, we understand the anguish of losing a furry family member. Traditional methods like calling shelters and putting up posters are time-consuming and often yield limited results. Public Facebook groups are full of unreliable people. And sadly no one checks online databases anymore.
              <br/><br/>
              So we decided to create a better and really efficient system to find lost animals. With our system, you can reach up to tens of thousands of people in the area where your pet is lost. It warms our hearts to see how many lost pets we've been able to return home.
            </p>


            

            <dl class="mt-10 space-y-4 text-base leading-7 text-gray-600">
              <div class="flex gap-x-4">
                <dt class="flex-none">
                  <span class="sr-only">Telephone</span>
                  <svg class="h-7 w-6 text-gray-400" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z" />
                  </svg>
                </dt>
                <dd><a class="hover:text-gray-900" href="tel:+44 7520 668505">+44 7520 668505</a></dd>
              </div>
              <div class="flex gap-x-4">
                <dt class="flex-none">
                  <span class="sr-only">Email</span>
                  <svg class="h-7 w-6 text-gray-400" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
                  </svg>
                </dt>
                <dd><a class="hover:text-gray-900" href="mailto:support@petgotlost.com">support@petgotlost.com</a></dd>
              </div>

              <div class="w-fit flex -space-x-4">
                <img class="w-10 h-10 border-2 border-white rounded-full" src="../assets/img/albert.jpg" alt="">
                <img class="w-10 h-10 border-2 border-white rounded-full" src="../assets/img/anicka.jpg" alt="">
                <div class="relative">
                  <img class="w-10 h-10 border-2 border-white rounded-full" src="../assets/img/jirka.jpg" alt="">
                  <span class="top-0 left-7 absolute  w-3.5 h-3.5 bg-green-400 border-2 border-white rounded-full"></span>
                </div>
              </div>
            </dl>

          </div>
          <div>
            <div class="aspect-h-2 aspect-w-3 overflow-hidden rounded-lg bg-gray-100">
              <img src="../assets/img/founders.jpg" alt="" class="object-cover object-center" />
            </div>
            <p class="mt-4 text-sm text-stone-500">Co-founders of PetGotLost.com</p>
          </div>

        </div>
        <div class="mt-16 grid grid-cols-1 gap-x-8 gap-y-10 lg:grid-cols-3">
          <div v-for="incentive in incentives" :key="incentive.name" class="sm:flex lg:block">
            <div class="flex align-middle h-16 sm:flex-shrink-0">
              <img class="max-w-[100px] max-h-16" :src="incentive.imageSrc" alt="" />
            </div>
            <div class="mt-4 sm:ml-6 sm:mt-0 lg:ml-0 lg:mt-6">
              <h3 class="text-sm font-medium text-gray-900">{{ incentive.name }}</h3>
              <p class="mt-2 text-sm text-gray-500">{{ incentive.description }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import aboutUsFeature1 from '../assets/svg/aboutUsFeature1.svg'
  import aboutUsFeature2 from '../assets/svg/aboutUsFeature2.svg'
  import aboutUsFeature3 from '../assets/svg/aboutUsFeature3.svg'

const incentives = [
  {
    name: "We'll start alerting the locals within hours",
    imageSrc: aboutUsFeature2,
    description: "Our team is available 24 hours a day, 7 days a week. Immediately after your registration we begin working on your campaign.",
  },
  {
    name: '100% Money Back Guarantee',
    imageSrc: aboutUsFeature1,
    description: "We'll refund all your money if we don't happen to reach as many people as we estimate in the allotted time.",
  },
  
  {
    name: 'Whole UK, 7 days a week',
    imageSrc: aboutUsFeature3,
    description:
      "It does not matter if you're from London, Edinburgh, a small village or abroad. We'll help you alert locals no matter what.",
  },
]
</script>


<script>
export default {
  name: 'AboutUsVue'
}
</script>