
<template>
  <MainHeader></MainHeader>

  <div class="bg-white px-6 py-16 lg:px-8">
    <div class="mx-auto max-w-5xl text-base leading-7 text-stone-700">
      <p class="text-base font-semibold leading-7 text-orange-600">Legal document</p>
      <h1 class="mt-2 text-3xl font-bold tracking-tight text-stone-900 sm:text-4xl">Terms and conditions of the PetGotLost service</h1>
      <div class="mt-10 max-w-5xl">
        <p class="mt-6"><span>These terms and conditions (hereinafter referred to as </span><strong>"terms and conditions"</strong><span>) of Jiří Štěpánek, with a registered address at Na Kopci 164, 251 62 Svojetice, identification number 07539002, registered in the Commercial Register kept by the Municipal Court in Prague, Section C, Insert 316877, regulate, in accordance with Section 1751 (1) of the Civil Code, as amended (hereinafter referred to as the </span><strong>"Civil Code"</strong><span>), the conditions and rules for using the PetGotLost service/help. This service is provided by Jiří Štěpánek (hereinafter referred to as the </span><strong>"company"</strong><span>) through petgotlost.com. The content of PetGotLost assistance includes providing help in the search for lost dogs and other domestic animals. The scope of assistance and its price are determined by the company's offer (package of individual forms of assistance) published on petgotlost.com. The company is authorized to change or supplement these terms. New terms are effective as of their publication on petgotlost.com. The contractual relationship between the company and the client is governed by the terms and conditions in effect at the time the order is delivered by the client to the company.&nbsp;</span></p>
        <p class="mt-6"><strong>The provider</strong><span> of individual forms of assistance is </span><strong>Jiří Štěpánek</strong><span>, with a registered address at Na Kopci 164, 251 62 Svojetice, identification number 07539002, registered in the Commercial Register kept by the Municipal Court in Prague, Section C, Insert 316877.</span></p>
        <p class="mt-6"><span>Contact details: jiri@palmmedia.cz, tel. +447520668505.&nbsp;</span></p>
        
        <h2 class="text-2xl font-bold tracking-tight text-gray-900 mb-6 mt-8">Execution of Assistance/Services</h2>
        <p class="mt-6"><span>The service operates online 24 hours a day.&nbsp;</span></p>
        <p class="mt-6"><span>The prerequisite for initiating the provision of the service - execution of paid services/help - is the payment of the service and the completion of the order placed on petgotlost.com.</span></p>
        
        <p class="mt-6"><span>During the above-mentioned period, the company provides assistance/service for </span><strong>"Search for pets using social media"</strong></p>
        
        <h2 class="text-2xl font-bold tracking-tight text-gray-900 mb-6 mt-8">Client</h2>
        <p class="mt-6"><span>The client can be a legal or natural person who has reached the age of 18 and is fully capable of legal acts related to these terms and conditions, gives consent to the processing of personal data, properly completes an order on petgotlost.com, and pays the company's fee, which is a prerequisite for initiating the provision of a specific form of assistance.&nbsp;</span></p>
        
        <h2 class="text-2xl font-bold tracking-tight text-gray-900 mb-6 mt-8">Contract Conclusion</h2>
        <p class="mt-6"><span>The contract under which the company undertakes to provide the client with assistance in finding a lost dog or domestic animal is concluded at the moment when the company receives the fee that is a prerequisite for initiating actions leading to finding the lost dog or domestic animal. The contract is also concluded when the company receives an order from the client through the petgotlost.com website. In the order, the client is obliged to provide accurate and truthful information. If there is a change in the client's information, the client must promptly notify the company by email to jiri@palmmedia.cz. The company is entitled to reject the order and not provide the requested assistance/service if it can reasonably believe that the client is violating these terms and conditions or has violated them in the past.&nbsp;</span></p>
        
        <h2 class="text-2xl font-bold tracking-tight text-gray-900 mb-6 mt-8">Guarantees</h2>
        <p class="mt-6"><span>Due to the nature of the assistance/service offered, the company does not guarantee the achievement of results, i.e., it is not responsible for finding the lost animal. Defects in the services provided must be sent to jiri@palmmedia.cz.</span></p>
        
        <h2 class="text-2xl font-bold tracking-tight text-gray-900 mb-6 mt-8">Consumer Contract</h2>
        <p class="mt-6"><span>This is a consumer contract if the client is a consumer, i.e., if they are a natural person and the provision of assistance/service is ordered by the client outside their business activities or outside the framework of their independent professional activity. In the case of a consumer contract, the client is entitled to consumer protection under legal regulations.&nbsp;</span></p>
        <p class="mt-6"><span>If the client is a consumer and a dispute arises between the company and the client from a contract that the company and the client fail to resolve directly, the client has the right to turn to the Czech Trade Inspection (www.coi.cz) with this dispute. Consumer rights are also defended by their interest associations and other entities for their protection. The client can exercise this right no later than 1 year from the day the company first asserted the right that is the subject of this consumer dispute.&nbsp;</span></p>
        
        <h2 class="text-2xl font-bold tracking-tight text-gray-900 mb-6 mt-8">Price and Payment Terms</h2>
        <p class="mt-6"><span>The price for assistance/service is the contractual price, it is stated for each offered assistance. By submitting the order, the client confirms agreement with the price. The price can be paid by credit card or any other method offered by the payment gateway.</span></p>
        <p class="mt-6"><strong>The client acknowledges that payment of the price for the selected scope of assistance/services is a prerequisite for initiating the execution of assistance.&nbsp;</strong></p>
        
        <h2 class="text-2xl font-bold tracking-tight text-gray-900 mb-6 mt-8">Withdrawal from the Contract</h2>
        <p class="mt-6"><span>The order can be canceled and the contract can be terminated only within the deadlines and under the conditions specified in these terms and conditions.</span></p>
        <p class="mt-6"><span>The order can be canceled by sending a cancellation email to jiri@palmmedia.cz. The client must state the order number in the email.&nbsp;</span></p>
        <p class="mt-6"><span>In the case of a consumer contract concluded between the company and the client, the client can withdraw from the contract within 14 days from the date of conclusion of the contract for the provision of assistance/services. Notice of withdrawal from the contract must be sent to the email jiri@palmmedia.cz. The company will confirm the receipt of the contract withdrawal without undue delay by email. Withdrawal from the contract cancels the contract from the beginning, and it is considered as if it were not concluded. In the event of withdrawal from the contract, the company will return the payment received, which it received from the client based on the contract, within 14 days from the withdrawal from the contract, to the account from which the payment was sent to the company or to the account specified in the withdrawal from the contract.&nbsp; In accordance with Section 1837 of the Civil Code, it is not possible to withdraw from the contract if the services have been provided with the express consent of the client before the expiration of the withdrawal period. The client acknowledges this consequence by agreeing to the provision of services before the expiration of the withdrawal period; in such a case, the payment received cannot be refunded.&nbsp;</span></p>
        <p class="mt-6"><span>The company reserves the right to withdraw from the contract if:</span></p>
        <p class="mt-6"><span>- the assistance/service cannot be provided under the original conditions for objective reasons,</span></p>
        <p class="mt-6"><span>- all important information (especially photos) related to the lost dog or domestic pet has not been provided in advance,</span></p>
        <p class="mt-6"><span>- performance becomes objectively impossible or illegal,</span></p>
        <p class="mt-6"><span>- in case of a material breach of these terms and conditions by the client.</span></p>
        <p class="mt-6"><span>In the event that any of the above-mentioned facts occur, the company will promptly inform the client of the withdrawal from the contract by email to the email from which the order was sent. Withdrawal is effective against the client at the moment when it is delivered to the client. The company will return the payment received by bank transfer to the account from which the client made the payment, within 5 days from the withdrawal from the contract.&nbsp;</span></p>
        
        <h2 class="text-2xl font-bold tracking-tight text-gray-900 mb-6 mt-8">Information on Personal Data Protection</h2>
        <p class="mt-6"><span>The company fulfills its information obligation to the client under Article 13 of Regulation (EU) 2016/679 of the European Parliament and of the Council, on the protection of natural persons with regard to the processing of personal data and on the free movement of such data, and repealing Directive 95/46/EC (General Data Protection Regulation) (hereinafter referred to as "GDPR") related to the processing of personal data of the buyer for the purpose of fulfilling the contract for the provision of assistance/services, for the purpose of negotiating a contract for the provision of assistance/services, and for the purpose of fulfilling the company's public law obligations through a special document.</span></p>
        
        <h2 class="text-2xl font-bold tracking-tight text-gray-900 mb-6 mt-8">Other Information</h2>
        <p class="mt-6"><span>When concluding the contract, means of distance communication are used. The costs incurred when using means of distance communication are borne by the client. All correspondence related to the contract between the company and the client takes place in written form, namely by email.&nbsp;</span></p>
        <p class="mt-6"><span>If any provision of these terms and conditions is invalid, ineffective, or unenforceable (or becomes such), the provision that most closely approximates the invalid, ineffective, or unenforceable provision will be used in its place. The invalidity, ineffectiveness, or unenforceability of one provision does not affect the validity of the other provisions.&nbsp;</span></p>
        
        <p class="mt-6"><span>These terms and conditions are valid and effective from 17.9.2019.&nbsp;</span></p>
        
        <p class="mt-6"><span>Jiří Štěpánek</span></p>
    </div>

    </div>
  </div>

  <MainFooter></MainFooter>
</template>


<script setup>
import { useSeoMeta } from '@vueuse/head'


useSeoMeta ({
	title: 'Terms and Conditions | PetGotLost.com',
	description: 'Explore our Terms and Conditions to ensure responsible and effective usage of our pet alert system.',
	ogDescription: 'Explore our Terms and Conditions to ensure responsible and effective usage of our pet alert system.',
	ogTitle: 'Terms and Conditions | PetGotLost.com',
	ogImage: 'https://petgotlost.com/og.jpg',
	twitterCard: 'summary_large_image',
  robots: "noindex"
});

</script>


<script>
import MainHeader from '../components/MainHeader.vue'
import MainFooter from '../components/MainFooter.vue'

export default {
  name: "VopView", 
  components: {
    MainHeader,
    MainFooter
  }
}
</script>