<template>
  <router-link :to="!pet.uid ? '/customer-stories' : `/pet/${pet.uid}`" class="relative block aspect-1 w-full" @mouseover="isHovering = true" @mouseout="isHovering = false">
    <span class="absolute bg-white z-20 -left-3 -top-3 border-2 py-1 px-2 text-sm rounded-md [shadow:_0_2px_6px_rgba(0,0,0,0.80);]">
      {{pet.status === "lost" ? "Lost 💨" : ""}}
      {{pet.status === "found" ? "Found 🥳" : ""}}
      {{pet.status === "deceased" ? "Pet Heaven 🖤" : ""}}
    </span>
    <span class="pointer-events-none block absolute bg-transparent border-transparent left-[-7px] top-[21px] z-0 border-t-gray-500 w-0 h-0 border-t-[6px] border-l-[6px] "></span>

    <div class="absolute cursor-pointer w-full h-full top-0 left-0 overflow-hidden shadow-lg rounded-lg">

      <div class="absolute z-10 top-0 left-0 w-full h-full hover:bg-black/50 bg-gradient-to-b from-transparent via-transparent to-black/40 transition-all">

        <div :class="isHovering && 'hidden'">
          <div class="absolute bottom-3 left-3">
            <h3 class="text-white font-bold text-2xl block [text-shadow:_0_2px_6px_rgba(0,0,0,0.80);]">{{ pet.petName }}</h3>
            <span class="text-white font-medium text-lg block [text-shadow:_0_2px_6px_rgba(0,0,0,0.80);]">{{ pet.petPlace }} 📍</span>
          </div>


          <div v-if="pet.petTime" class="absolute top-3 right-3">
            <h4 class="text-white font-bold text-xl text-right [text-shadow:_0_2px_6px_rgba(0,0,0,0.80);]">{{ pet.petTime }}</h4>
            <span class="text-white font-medium text-sm text-right w-full block [text-shadow:_0_2px_6px_rgba(0,0,0,0.80);]">📆 Last Seen</span>
          </div>
        </div>


        <div class="absolute top-1/2 -translate-y-1/2 w-full" :class="!isHovering && 'hidden'">
          <dl class="-my-3 divide-y divide-white/30 text-xs leading-6 w-[calc(100%-30px)] mx-auto">

            <div v-if="pet.petAge?.length > 0" class="flex justify-between gap-x-4 py-2">
              <dt class="text-white text-sm">⏳ Age</dt>
              <dd class="text-white text-sm">{{ pet.petAge }}</dd>
            </div>

            <div v-if="pet.petBreed?.length > 0" class="flex justify-between gap-x-4 py-2">
              <dt class="text-white text-sm">📖 Breed</dt>
              <dd class="text-white text-sm">{{ pet.petBreed }}</dd>
            </div>

            <div v-if="pet.petColor?.length > 0" class="flex justify-between gap-x-4 py-2">
              <dt class="text-white text-sm">🎨 Colour</dt>
              <dd class="text-white text-sm">{{ pet.petColor }}</dd>
            </div>

            <div v-if="pet.petSex?.length > 0" class="flex justify-between gap-x-4 py-2">
              <dt class="text-white text-sm">🔎 Gender</dt>
              <dd class="text-white text-sm">{{ pet.petSex }}</dd>
            </div>

            <div v-if="pet.userPhone?.length > 0" class="flex justify-between gap-x-4 py-2">
              <dt class="text-white text-sm">☎️ Phone</dt>
              <dd class="text-white text-sm">{{ pet.userPhone }}</dd>
            </div>

            <div v-if="pet.petReward" class="flex justify-between gap-x-4 py-2">
              <dt class="text-white text-sm">💰 Reward</dt>
              <dd class="text-white text-sm">{{ pet.petReward }}</dd>
            </div>


          </dl>
        </div>

      </div>

      {{ isHovering && "hovering"}}


      




      <div class="absolute inset-0 block z-0 w-full h-full" aria-hidden="true">
        <!-- <img class="min-w-full min-h-full absolute top-0 left-0 object-cover" :src="pet.petImg.data.attributes.url" :alt="petName" /> -->



        <img 
          v-if="typeof this.pet.petImg.data.attributes.formats?.thumbnail != `undefined`"
          class="min-w-full min-h-full absolute top-0 left-0 object-cover" 
          :src="pet.petImg.data.attributes.formats.thumbnail.url"
          :alt="petName" 
        />

        <img 
          v-else
          class="min-w-full min-h-full absolute top-0 left-0 object-cover" 
          :src="pet.petImg.data.attributes.url" 
          :alt="petName"
        />


      </div>
    </div>
  </router-link>







</template>


<script>
export default {
  name: "PetBubble",
  data() {
    return {
      isHovering: false
    }
  },
  props: {
    pet: Object,
  },
}
</script>