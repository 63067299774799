<template>
	<MainHeader></MainHeader>

	<div class="bg-stone-100 py-10 pb-16 sm:py-24 isolate">

    <div class="relative isolate -z-10">
      <svg class="absolute inset-x-0 top-0 -z-10 h-[64rem] w-full stroke-stone-200 [mask-image:radial-gradient(32rem_32rem_at_center,white,transparent)]" aria-hidden="true">
        <defs>
          <pattern id="1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84" width="200" height="200" x="50%" y="-1" patternUnits="userSpaceOnUse">
            <path d="M.5 200V.5H200" fill="none" />
          </pattern>
        </defs>
        <svg x="50%" y="-1" class="overflow-visible fill-stone-50">
          <path d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z" stroke-width="0" />
        </svg>
        <rect width="100%" height="100%" stroke-width="0" fill="url(#1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84)" />
      </svg>
      <div class="absolute left-1/2 right-0 top-0 -z-10 -ml-24 transform-gpu overflow-hidden blur-3xl lg:ml-24 xl:ml-48" aria-hidden="true">
        <div class="aspect-[801/1036] w-[50.0625rem] bg-gradient-to-tr from-[#FF9A45] to-[#FF405B] opacity-20" style="clip-path: polygon(63.1% 29.5%, 100% 17.1%, 76.6% 3%, 48.4% 0%, 44.6% 4.7%, 54.5% 25.3%, 59.8% 49%, 55.2% 57.8%, 44.4% 57.2%, 27.8% 47.9%, 35.1% 81.5%, 0% 97.7%, 39.2% 100%, 35.2% 81.4%, 97.2% 52.8%, 63.1% 29.5%)" />
      </div>
    </div>

    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <div class="mx-auto grid max-w-2xl grid-cols-1 items-start gap-x-8 gap-y-12 sm:gap-y-24 lg:mx-0 lg:max-w-none lg:flex lg:justify-between">
        <div>
          <div class="text-base leading-7 text-stone-700 lg:max-w-xl">
            <p class="text-base font-semibold leading-7 text-orange-600">Explanation of our method</p>
            <h1 class="mt-2 text-3xl font-bold tracking-tight text-stone-900 sm:text-4xl">How exactly does a search campaign work?</h1>
            <div class="max-w-xl">
              <p class="mt-6">Compared to other portals, we do not only provide a database of lost animals. We have created a comprehensive search system with paid ads on social media.</p>
              <p class="mt-8">When a pet is lost, we want to do everything we can to make sure the news of their loss reaches every person in the area. Unfortunately, we can't achieve that just by putting up posters and posting in public groups on the internet.</p>
              <p class="mt-8">As part of our agency approach, we are able to create the exact same "marketing" (in our case, alert) campaigns that are commonly used by large companies on Facebook and Instagram. These ads, with a description and photo of your pet, are then able to be very precisely targeted to any location we choose. At the same time, we also target the group of users most likely to have seen your pet.</p>
            </div>
          </div>
          <dl class="mt-10 grid grid-cols-1 gap-8 border-t border-stone-900/10 pt-10 sm:grid-cols-3">
            <div v-for="(stat, statIdx) in stats" :key="statIdx">
              <dt class="text-sm font-semibold leading-6 text-stone-600">{{ stat.label }}</dt>
              <dd class="mt-2 text-3xl font-bold leading-10 tracking-tight text-stone-900">{{ stat.value }}</dd>
            </div>
          </dl>
          <div class="mt-10 flex">
            <a href="/about-us" class="text-base font-semibold leading-7 text-orange-600">Find out more about us <span aria-hidden="true">&rarr;</span></a>
          </div>
        </div>
        <div class="lg:pl-4 lg:w-2/5">
          <div class="relative overflow-hidden h-fit rounded-3xl bg-transparent lg:w-full  w-4/5  sm:w-80 lg:max-w-40">
            <!-- <iframe class="w-full aspect-[16/9]" src="https://www.youtube.com/embed/nABwpXFwhA0"></iframe> -->
            <img src="../assets/svg/walkingLady.svg" class="inset-0 w-4/5 bg-transparent block ml-auto " alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>


	<div class="bg-white pt-16 sm:pt-24">
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <div class="mx-auto max-w-2xl lg:mx-0">
        <h2 class="text-3xl font-bold tracking-tight text-stone-900 sm:text-4xl">Our strategies for finding lost pets</h2>
        <p class="mt-6 text-lg leading-8 text-stone-600">What we do to make sure your lost dog or cat is returned home safely.</p>
      </div>
      <div class="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
        <dl class="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
          <div v-for="feature in features" :key="feature.name" class="flex flex-col">
            <dt class="text-xl font-semibold leading-7 text-stone-900">
              <div class="mb-8 flex rounded-lg">
                <!-- <component :is="feature.icon" class="h-6 w-6 text-white" aria-hidden="true" /> -->
                <img :src="feature.icon" class="w-full max-w-[300px] aspect-1" alt="" />
              </div>
              {{ feature.name }}
            </dt>
            <dd class="mt-4 flex flex-auto flex-col text-base leading-7 text-stone-600">
              <p class="flex-auto">{{ feature.description }}</p>
            </dd>
          </div>
        </dl>

        <router-link to="/register" class="cursor-pointer block w-fit mt-12 lg:mx-auto text-md font-semibold leading-6 text-white px-8 py-3 bg-gradient-to-r rounded-md from-[#FF9A45] hover:from-[#E68B3E] to-[#FF405B] hover:to-[#E53952] transition-all hover:drop-shadow-sm drop-shadow-md">Report a Lost Pet</router-link>

      </div>
    </div>
  </div>


	<div class="overflow-hidden bg-white pt-24 sm:pt-32">
    <div class="mx-auto max-w-7xl md:px-6 lg:px-8">
      <div class="grid grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:grid-cols-2 lg:items-start">
        <div class="px-6 md:px-0 lg:pr-4 lg:pt-4">
          <div class="mx-auto max-w-2xl lg:mx-0 lg:max-w-lg">
            <h2 class="text-base font-semibold leading-7 text-orange-600">Why are we doing it this way?</h2>
            <p class="mt-2 text-3xl font-bold tracking-tight text-stone-900 sm:text-4xl">The main benefits of searching through paid ads</p>
            <p class="mt-6 text-lg leading-8 text-stone-600">Searching this way, while not free, is dramatically more effective than any other method.</p>
            <dl class="mt-10 max-w-xl space-y-8 text-base leading-7 text-stone-600 lg:max-w-none">
              <div v-for="feature in features2" :key="feature.name" class="relative pl-9">
                <dt class="inline font-semibold text-stone-900">
                  <component :is="feature.icon" class="absolute left-1 top-1 h-5 w-5 text-orange-600" aria-hidden="true" />
                  {{ feature.name }}
                </dt>
                {{ ' ' }}
                <dd class="block">{{ feature.description }}</dd>
              </div>
            </dl>
          </div>
        </div>
        <div class="sm:px-6 lg:px-0">

          <div class="relative isolate overflow-hidden bg-stone-50 sm:mx-auto sm:max-w-2xl sm:rounded-3xl lg:mx-0 lg:max-w-none flex items-center justify-center py-8 sm:py-12">

            <img src="../assets/svg/stats.svg" class="h-96 max-w-sm " alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>



	<FaqSection></FaqSection>

	<MainFooter></MainFooter>
</template>


<script setup>

import { MegaphoneIcon, MapPinIcon, CalendarDaysIcon } from '@heroicons/vue/20/solid'
import how1 from '../assets/svg/how1.svg'
import how2 from '../assets/svg/how2.svg'
import how3 from '../assets/svg/how3.svg'
import { useSeoMeta } from '@vueuse/head'

useSeoMeta ({
	title: 'How exactly does a search campaign work? | PetGotLost.com',
	description: "1,000+ pets reunited across the UK. What is our strategy? Search accuracy of 1 mile². Reach over 16,000 people. We'll launch everything within 3 hours.",
	ogDescription: "1,000+ pets reunited across the UK. What is our strategy? Search accuracy of 1 mile². Reach over 16,000 people. We'll launch everything within 3 hours.",
	ogTitle: 'How exactly does a search campaign work? | PetGotLost.com',
	ogImage: 'https://petgotlost.com/og.jpg',
	twitterCard: 'summary_large_image',
});


const stats = [
  { label: 'Search accuracy', value: '1 mile²' },
  { label: 'On average we alert', value: '16k people' },
  { label: 'We launch everything', value: '<3 hours' },
]

const features = [
  {
    name: 'We create Facebook and Instagram ads',
    description:
      'We manually create and run social media ads. All ads are tailor-made according to pre-approved rules.',
    icon: how1,
  },
  {
    name: 'We target all people in the area',
    description:
      "We'll map out where the animal might be located. We then run all the ads in that area. If we're not sure, we will test multiple locations at once.",
    icon: how2,
  },
  {
    name: "Campaign adjustments with every clue",
    description:
      "As soon as people start leaving us comments with more information about where and when they saw the animal, we'll immediately adjust our ad targeting accordingly and keep you informed.",
    icon: how3,
  },
]

const features2 = [
  {
    name: 'Dramatically more effective than regular posts.',
    description:
      "With a traditional Facebook group post, you'll reach only 6.4% of people on average. Compared to SMS search, we reach 29 times more people. With email searches, people often have their message dropped in the spam. Only with us are you able to reach such a large number of people in such a short time.",
    icon: MegaphoneIcon,
  },
  {
    name: 'We can reach up to tens of thousands of people in any given location.',
    description: "Thanks to our marketing tools, we can reach around 16,000 people for an average campaign. Our record for one Labrador campaign is over 110,000 people! In fact, in addition to the reach we pay for, many people share the search posts further among their friends out of goodwill.",
    icon: MapPinIcon,
  },
  {
    name: 'Compared to a traditional post, the ads are displayed for several days.',
    description: "A regular Facebook or Instagram post is only shown to people for about 3 hours. With us, it's 7 days. This is due to the fact that, like ads, our searches are always shown as a priority.",
    icon: CalendarDaysIcon,
  },
]
</script>

<script>
import MainHeader from '../components/MainHeader.vue'
import MainFooter from '../components/MainFooter.vue'
import FaqSection from '@/components/FaqSection.vue'
import BreadCrumb from '@/components/BreadCrumb.vue'

export default {
	name: 'HowView',
	components: {
		MainHeader,
		MainFooter,
		FaqSection,
    BreadCrumb,
	}
}
</script>