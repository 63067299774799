<template>
    <div>
      <input
        ref="input"
        type="file"
        name="image"
        accept="image/*"
        @change="setImage"
        class="sr-only"
      />
      <div class="">
        <section class="cropper-area">
          <div v-if="imgSrc" class="img-cropper">
            <vue-cropper
              ref="cropper"
              :aspect-ratio="1 / 1"
              :src="imgSrc"
              :viewMode="3"
              preview=".preview"
            />
          </div>
          <div class="mt-8">


            <input v-if="imgSrc" @click="cropImage" type="button" value="Choose" class="mr-4 rounded-md bg-orange-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600">
            <PetButton @click="showFileChooser" text="Select image" />
          </div>
        </section>
      </div>
    </div>
  </template>
  
  <script>
  import VueCropper from 'vue-cropperjs';
  import 'cropperjs/dist/cropper.css';

  import PetButton from './PetButton.vue'



  export default {
    components: {
      VueCropper,
      PetButton
    },
    props: {
      src: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        imgSrc: '',
        cropImg: '',
        data: null,
      };
    },
    methods: {
      cropImage() {
        const img = this.$refs.cropper.getCroppedCanvas().toDataURL();
        this.$emit('imageCropped', img)
      },
      setImage(e) {
        const file = e.target.files[0];
        if (file.type.indexOf('image/') === -1) {
          alert('Please select an image file');
          return;
        }
        if (typeof FileReader === 'function') {
          const reader = new FileReader();
          reader.onload = (event) => {
            this.imgSrc = event.target.result;

            this.$refs.cropper.replace(event.target.result);
          };
          reader.readAsDataURL(file);
        } else {
          alert('Sorry, FileReader API not supported');
        }
      },
      showFileChooser() {
        this.$refs.input.click();
      },
    },
  };

  </script>
  
  <style>
  /* input[type="file"] {
    display: none;
  }
  .content {
    display: flex;
    justify-content: space-between;
  }
  .cropper-area {
    width: 614px;
  }
  .actions {
    margin-top: 1rem;
  }
  .actions a {
    display: inline-block;
    padding: 5px 15px;
    background: #0062CC;
    color: white;
    text-decoration: none;
    border-radius: 3px;
    margin-right: 1rem;
    margin-bottom: 1rem;
  }
  .preview-area {
    width: 250px;
  }
  .preview-area p {
    font-size: 1.25rem;
    margin: 0;
    margin-bottom: 1rem;
  }
  .preview-area p:last-of-type {
    margin-top: 1rem;
  }
  .preview {
    width: 100%;
    height: calc(372px * (9 / 16));
    overflow: hidden;
  }
  .crop-placeholder {
    width: 100%;
    height: 200px;
    background: #ccc;
  }
  .cropped-image img {
    max-width: 100%;
  } */
  </style>