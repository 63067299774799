<template>
  <MainHeader></MainHeader>


  <div class="relative isolate bg-stone-100 py-12 sm:py-24 lg:pb-32">
    <div class="absolute inset-x-0 top-1/2 -z-10 -translate-y-1/2 transform-gpu overflow-hidden opacity-30 blur-3xl" aria-hidden="true">
      <div class="ml-[max(50%,38rem)] aspect-[1313/771] w-[82.0625rem] bg-gradient-to-tr from-[#FF9A45] to-[#FF405B]" style="clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)" />
    </div>
    <div class="absolute inset-x-0 top-0 -z-10 flex transform-gpu overflow-hidden pt-32 opacity-25 blur-3xl sm:pt-40 xl:justify-end" aria-hidden="true">
      <div class="ml-[-22rem] aspect-[1313/771] w-[82.0625rem] flex-none origin-top-right rotate-[30deg] bg-gradient-to-tr from-[#FF9A45] to-[#FF405B] xl:ml-0 xl:mr-[calc(50%-12rem)]" style="clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)" />
    </div>
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <div class="mx-auto max-w-4xl text-center">
        <h2 class="text-lg font-semibold leading-8 tracking-tight text-orange-500">Good Endings & Reviews</h2>
        <p class="mt-2 text-2xl font-bold tracking-tight text-stone-900 sm:text-4xl lg:text-5xl">We've helped hundreds of people reunite with their lost pets</p>
      </div>
      <div class="mx-auto mt-16 grid max-w-2xl grid-cols-1 grid-rows-1 gap-8 text-sm leading-6 text-gray-900 sm:mt-20 sm:grid-cols-2 xl:mx-0 xl:max-w-none xl:grid-flow-col xl:grid-cols-4">
        <figure class="col-span-2 hidden sm:block sm:rounded-2xl sm:bg-white sm:shadow-lg sm:ring-1 sm:ring-gray-900/5 xl:col-start-2 xl:row-end-1">
          <blockquote class="p-10 text-lg font-semibold leading-8 tracking-tight text-gray-900">
            <p>{{ `“${featuredTestimonial.body}”` }}</p>
          </blockquote>
          <figcaption class="flex items-center gap-x-4 border-t border-stone-900/10 px-6 py-4">
            <img class="h-10 w-10 flex-none rounded-full bg-stone-50" :src="featuredTestimonial.author.imageUrl" alt="" />
            <div class="flex-auto">
              <div class="font-semibold">{{ featuredTestimonial.author.name }}</div>
              <div class="text-stone-600">{{ `${featuredTestimonial.author.handle}` }}</div>
            </div>
            <div class="text-2xl">
              ⭐️⭐️⭐️⭐️⭐️
            </div>
          </figcaption>
        </figure>
        <div v-for="(columnGroup, columnGroupIdx) in testimonials" :key="columnGroupIdx" class="space-y-8 xl:contents xl:space-y-0">
          <div v-for="(column, columnIdx) in columnGroup" :key="columnIdx" :class="[(columnGroupIdx === 0 && columnIdx === 0) || (columnGroupIdx === testimonials.length - 1 && columnIdx === columnGroup.length - 1) ? 'xl:row-span-2' : 'xl:row-start-1', 'space-y-8']">
            <figure v-for="testimonial in column" :key="testimonial.author.handle" class="rounded-2xl bg-white p-6 shadow-lg ring-1 ring-gray-900/5">
              <blockquote class="text-stone-900">
                <p>{{ `“${testimonial.body}”` }}</p>
              </blockquote>
              <figcaption class="mt-6 flex items-center gap-x-4">
                <img class="h-10 w-10 rounded-full bg-stone-50" :src="testimonial.author.imageUrl" alt="" />
                <div>
                  <div class="font-semibold">{{ testimonial.author.name }}</div>
                  <div class="text-stone-600">{{ `${testimonial.author.handle}` }}</div>
                </div>
              </figcaption>
            </figure>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="relative isolate overflow-hidden bg-stone-900 py-12 sm:py-32">
    <img src="https://images.unsplash.com/photo-1604848698030-c434ba08ece1?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1074&q=80" alt="" class="absolute inset-0 -z-10 h-full w-full object-cover opacity-20 blur-sm" />
    <div class="relative mx-auto max-w-7xl px-6 lg:px-8">
      <div class="absolute -bottom-8 -left-96 -z-10 transform-gpu blur-3xl sm:-bottom-64 sm:-left-40 lg:-bottom-32 lg:left-8 xl:-left-10" aria-hidden="true">
        <div class="aspect-[1266/975] w-[79.125rem] bg-gradient-to-tr from-[#FF405B] to-[#FF9A45] opacity-20" style="clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)" />
      </div>
      <div class="mx-auto max-w-2xl lg:mx-0 lg:max-w-full">
        <h2 class="text-base font-semibold leading-8 text-orange-400">A service you can trust</h2>
        <p class="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">Every pet deserves a swift and safe return! ❤️</p>
        <p class="mt-6 text-lg leading-8 text-stone-300">In our 5 years of operating, we have learned a lot about helping people find their missing furry friends. You are not alone in this.</p>
      </div>
      <dl class="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-10 text-white sm:mt-20 sm:grid-cols-2 sm:gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-4">
        <div v-for="stat in stats" :key="stat.id" class="flex flex-col gap-y-3 border-l border-white/10 pl-6">
          <dt class="text-sm leading-6">{{ stat.name }}</dt>
          <dd class="order-first text-3xl font-semibold tracking-tight">{{ stat.value }}</dd>
        </div>
      </dl>
    </div>
  </div>

  <RecentlySaved></RecentlySaved>

  <AboutUsSection></AboutUsSection>

  <CtaMain class="sm:mt-24"></CtaMain>

  <FaqSection></FaqSection>
  
  <MainFooter></MainFooter>
</template>


<script setup>

import { useSeoMeta } from '@vueuse/head'
import customerViteslav from '../assets/img/customerViteslav.png'
import customerZoe from '../assets/img/customerZoe.jpg'
import customer1 from '../assets/img/customer1.png'
import customer2 from '../assets/img/customer2.png'
import customerArny from '../assets/img/customerArny.jpeg'
import customer3 from '../assets/img/customer3.png'
import customerBarnie from '../assets/img/customerBarnie.jpeg'
import customerSushi from '../assets/img/customerSushi2.jpeg'
import customerMalicek2 from '../assets/img/customerMalicek2.jpeg'


useSeoMeta ({
	title: 'Good Endings & Reviews: 1,000+ reunited pets | PetGotLost.com',
	description: 'We have helped hundreds of people find their lost pets across the UK. We have over 5 years experience of searching for lost dogs and cats.',
	ogDescription: 'We have helped hundreds of people find their lost pets across the UK. We have over 5 years experience of searching for lost dogs and cats.',
	ogTitle: 'Good Endings & Reviews: 1,000+ reunited pets | PetGotLost.com',
	ogImage: 'https://petgotlost.com/og.jpg',
	twitterCard: 'summary_large_image',
});

const featuredTestimonial = {
  body: 'We recently had two dogs gone missing, Toby and Alex. Now we are all happily together again. Thank you to all of you who participated in the search through the petgotlost.com service and provided us with support. It is very important that such a service exists and, most importantly, that it works. The community of people, authorities, associations and all those willing to help, who were contacted in a very short period of time after the loss, would be beyond the power of an individual. And it is the speed and quality of getting the information out to the right people and places that is the key to successfully reuniting a lost pet.',
  author: {
    name: 'James H.',
    handle: 'Happy owner',
    imageUrl: customerViteslav,
  },
}

const testimonials = [
  [
    [
      {
        body: 'Our dog was already known to a number of strangers we spoke to randomly during our search.',
        author: {
          name: 'Charlotte W.',
          handle: 'Happy owner',
          imageUrl: customer1,
        },
      },
      {
        body: 'We got a tip from a lady who goes to feed the stray cats that she noticed her and she is not one of the others she meets there. I would really like to thank you for your help, without your help we would still be searching or not have found it at all. Thank you.',
        author: {
          name: 'Olivia Z.',
          handle: 'Happy owner',
          imageUrl: customer2,
        },
      },
      {
        body: "Barnie escaped from a family's backyard one evening. His owners searched for him for two days. Within 8 hours of launching our campaign, we managed to reach more than 12,000 people, including his rescuer, who handed him over to his owners the same day.",
        author: {
          name: 'Barnie',
          handle: 'Customer story',
          imageUrl: customerBarnie,
        },
      }
      // More testimonials...
    ],
    [
      {
        body: 'Milo is a cat who has never been outside. But one day he ran away and no one knew how to find him. On the first day of the campaign, we reached out to 12,000 people in the area and managed to return the cat home safely.',
        author: {
          name: 'Milo',
          handle: 'Customer story',
          imageUrl: customerMalicek2,
        },
      },
      // More testimonials...
    ],
  ],
  [
    [
      {
        body: 'Sushi went missing from a block of flats in a very populated part of London. In 2 days, we contacted over 9,000 people in the neighborhood. As soon as the person who took this cat in learned about the loss, he contacted the owner himself and returned Sushi home.',
        author: {
          name: 'Sushi',
          handle: 'Customer story',
          imageUrl: customerSushi,
        },
      },
      // More testimonials...
    ],
    [
      {
        body: 'Zoe got lost on a walk and was strayed for 9 days. Thanks to your service, the general neighborhood knew about her and we received calls every day from people all over the county that they had apparently seen our dog. After a lot of bogus calls, we finally heard from a gentleman with the right tip and Zoe was found in a field just a few miles from home.',
        author: {
          name: 'William H.',
          handle: 'Happy owner',
          imageUrl: customerZoe,
        },
      },
      {
        body: 'One does not realize the value of such a service until the time when one really needs it...',
        author: {
          name: 'Amelia N.',
          handle: 'Happy owner',
          imageUrl: customer3,
        },
      },
      {
        body: 'Cooper went missing while on security duty. Within a few days we managed to reach over 37 thousand people from his town and its surroundings. Thanks to that, we were able to find him.',
        author: {
          name: 'Cooper, police dog',
          handle: 'Customer story',
          imageUrl: customerArny,
        },
      },
      // More testimonials...
    ],
  ],
  
]

const stats = [
  { id: 1, name: 'In business', value: '5 years' },
  { id: 2, name: 'Are alerted on average', value: '16,000 locals' },
  { id: 3, name: 'We are here for you', value: '7 days a week' },
  { id: 4, name: 'Thanks to our campaigns', value: '74% success rate' },
]
</script>


<script>

import MainHeader from '../components/MainHeader.vue'
import MainFooter from '../components/MainFooter.vue'
import RecentlySaved from '@/components/RecentlySaved.vue';
import AboutUsSection from '@/components/AboutUsSection.vue';
import CtaMain from '@/components/CtaMain.vue';
import FaqSection from '@/components/FaqSection.vue';

export default {
  name: 'TestemonialsView',
  components: {
    MainHeader,
    MainFooter,
    RecentlySaved,
    AboutUsSection,
    CtaMain,
    FaqSection,
  },
}
</script>