<template>
  <div class="bg-stone-100">
    <div class="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8">
      <div class="md:flex md:items-center md:justify-between">
        <h2 class="text-2xl font-bold tracking-tight text-stone-900">Recently Reunited Pets ❤️</h2>
        <router-link to="/lost-pets" class="hidden text-sm font-medium text-orange-600 hover:text-orange-500 md:block">
          View All Lost Pets
          <span aria-hidden="true"> &rarr;</span>
        </router-link>
      </div>

      <div class="mt-10 grid grid-cols-2 xs:max-w-none xs:grid-cols-2 gap-x-4 gap-y-10 sm:gap-x-6 lg:grid-cols-4 lg:gap-y-0 lg:gap-x-8">



        <div v-for="pet in pets" :key="pet.id">
          <PetBubble :pet="pet"></PetBubble>


        </div>






        
      </div>

      <div class="mt-8 text-sm md:hidden">
        <router-link to="/lost-pets" class="font-medium text-orange-600 hover:text-orange-500">
          View All Lost Pets
          <span aria-hidden="true"> &rarr;</span>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script setup>

import sushiPic from '../assets/img/customerSushi.jpeg';
import neliPic from '../assets/img/customerNeli.jpeg';
import malicekPic from '../assets/img/customerMalicek.jpeg';
import bertPic from '../assets/img/customerBert.jpeg';

const pets = [
  {
    petAge: "4",
    petColor: "Dark brown",
    petImg: {data: {attributes: {url: neliPic}}},
    petName: "Neli",
    petPlace: "Edimburgh",
    petRegion: "Scotland",
    petSex: "Female",
    petSize: "Small",
    status: "found",
  },
  {
    petAge: "2",
    petBreed: "Home cat",
    petColor: "Gray and white",
    petImg: {data: {attributes: {url: sushiPic}}},
    petName: "Sushi",
    petPlace: "London",
    petRegion: "England",
    petSex: "Female",
    status: "found",
  },
  {
    petAge: "5",
    petBreed: "Border Collie",
    petColor: "Black and white",
    petImg: {data: {attributes: {url: bertPic}}},
    petName: "Bert",
    petPlace: "Newcastle",
    petRegion: "England",
    petSex: "Male",
    status: "found",
  },
  {
    petAge: "2",
    petBreed: "Cat",
    petColor: "White",
    petImg: {data: {attributes: {url: malicekPic}}},
    petName: "Felix",
    petPlace: "Sheffield",
    petRegion: "England",
    petSex: "Male",
    status: "found",
  },




  // More products...
]
</script>

<script>
import PetBubble from './PetBubble.vue';


export default {
  name: 'RecentlySaved',
  components: {
    PetBubble
  }, 
}

</script>