
<template>
  <MainHeader></MainHeader>

  <div class="bg-white px-6 py-16 lg:px-8">
    <div class="mx-auto max-w-5xl text-base leading-7 text-stone-700">
      <p class="text-base font-semibold leading-7 text-orange-600">Legal document</p>
      <h1 class="mt-2 text-3xl font-bold tracking-tight text-stone-900 sm:text-4xl">Privacy Policy</h1>
      

      <div class="mt-10 max-w-5xl">
        <h2 class="text-2xl font-bold tracking-tight text-gray-900 mb-6 mt-8">I. Basic Provisions</h2>
        <ol>
            <li><span>The controller of personal data pursuant to Article 4(7) of the Regulation (EU) 2016/679 of the European Parliament and of the Council on the protection of natural persons with regard to the processing of personal data and on the free movement of such data (hereinafter referred to as "<b>GDPR</b>") is Jiří Štěpánek ID: 07539002, with registered office at Na Kopci 164, 251 62 Svojetice (hereinafter referred to as "<b>controller</b>").</span></li>
            <li><span>Contact details of the controller are:</span></li>
        </ol>
        <p class="mt-6"><span>address: Na Kopci 164, 251 62 Svojetice</span></p>
        <p><span>email: jiri@palmmedia.cz</span></p>
        <p><span>phone: +447520668505</span></p>
        <ol class="mt-6" start="3">
            <li class="mt-6"><span>Personal data means any information relating to an identified or identifiable natural person; an identifiable natural person is one who can be identified, directly or indirectly, in particular by reference to an identifier such as a name, an identification number, location data, an online identifier, or one or more factors specific to the physical, physiological, genetic, mental, economic, cultural, or social identity of that natural person.</span></li>
            <li class="mt-6"><span>The controller has not appointed a data protection officer.</span></li>
        </ol>
        <h2 class="text-2xl font-bold tracking-tight text-gray-900 mb-6 mt-8">II. Sources and Categories of Processed Personal Data</h2>
        <ol>
            <li class="mt-6"><span>The controller processes personal data that you have provided or personal data that the controller has obtained based on the fulfillment of your order.</span></li>
            <li class="mt-6"><span>The controller processes your identification and contact details and data necessary for the fulfillment of the contract.<span class="Apple-converted-space">&nbsp;</span></span></li>
        </ol>

        <h2 class="text-2xl font-bold tracking-tight text-gray-900 mb-6 mt-8">III. Legal Basis and Purpose of Processing Personal Data</h2>
        <ol class="mt-6">
            <li><span>The legal basis for processing personal data is<span class="Apple-converted-space">&nbsp;</span></span></li>
        </ol>
        <ul>
            <li class="mt-6"><span></span><span>fulfillment of the contract between you and the controller according to Article 6(1)(b) GDPR,</span></li>
            <li class="mt-6"><span></span><span>the legitimate interest of the controller in providing direct marketing (especially for sending commercial communications and newsletters) according to Article 6(1)(f) GDPR,</span></li>
        </ul>
        <ol start="2">
            <li class="mt-6"><span>The purpose of processing personal data is</span></li>
        </ol>
        <ul>
            <li class="mt-6"><span></span><span>processing your order and fulfilling the rights and obligations arising from the contractual relationship between you and the controller; when ordering, personal data necessary for successful order processing (name and address, contact) are required, providing personal data is a necessary requirement for entering into and fulfilling the contract, and without providing personal data, it is not possible to conclude or fulfill the contract on the part of the controller,</span></li>
            <li class="mt-6"><span></span><span>sending commercial communications and conducting other marketing activities.<span class="Apple-converted-space">&nbsp;</span></span></li>
        </ul>
        <ol start="3">
            <li class="mt-6"><span>The controller does not engage in automatic individual decision-making within the meaning of Article 22 GDPR.</span></li>
        </ol>
        <h2 class="text-2xl font-bold tracking-tight text-gray-900 mb-6 mt-8">IV. Data Retention Period</h2>
        <ol>
            <li class="mt-6"><span>The controller retains personal data<span class="Apple-converted-space">&nbsp;</span></span></li>
        </ol>
        <ul>
            <li class="mt-6"><span></span><span>for the time necessary to perform the rights and obligations arising from the contractual relationship between you and the controller and enforce claims under these contractual relationships (for a period of 15 years from the termination of the contractual relationship).<span class="Apple-converted-space">&nbsp;</span></span></li>
        </ul>
        <ol start="2">
            <li class="mt-6"><span>After the expiration of the retention period, the controller will erase personal data.<span class="Apple-converted-space">&nbsp;</span></span></li>
        </ol>
        <h2 class="text-2xl font-bold tracking-tight text-gray-900 mb-6 mt-8">V. Recipients of Personal Data (Controller's Subcontractors)</h2>
        <ol>
            <li class="mt-6"><span>Recipients of personal data are individuals<span class="Apple-converted-space">&nbsp;</span></span></li>
        </ol>
        <ul>
            <li class="mt-6"><span></span><span>involved in delivering goods/services/processing payments based on the contract,<span class="Apple-converted-space">&nbsp;</span></span></li>
            <li class="mt-6"><span></span><span>providing e-shop operation services and other services related to e-shop operation,</span></li>
            <li class="mt-6"><span></span><span>providing marketing services.</span></li>
        </ul>
        <ol start="2">
            <li class="mt-6"><span>The controller intends to transfer personal data to a third country (outside the EU) or an international organization. Recipients of personal data in third countries are providers of mailing and cloud services.<span class="Apple-converted-space">&nbsp;</span></span></li>
        </ol>
        <h2 class="text-2xl font-bold tracking-tight text-gray-900 mb-6 mt-8">VI. Your Rights</h2>
        <ol>
            <li class="mt-6"><span>Under the conditions set out in GDPR, you have<span class="Apple-converted-space">&nbsp;</span></span></li>
        </ol>
        <ul>
            <li class="mt-6"><span></span><span>the right to access your personal data according to Article 15 GDPR,<span class="Apple-converted-space">&nbsp;</span></span></li>
            <li class="mt-6"><span></span><span>the right to rectify personal data according to Article 16 GDPR or restrict processing according to Article 18 GDPR.<span class="Apple-converted-space">&nbsp;</span></span></li>
            <li class="mt-6"><span></span><span>the right to erase personal data according to Article 17 GDPR.<span class="Apple-converted-space">&nbsp;</span></span></li>
            <li class="mt-6"><span></span><span>the right to object to processing according to Article 21 GDPR and<span class="Apple-converted-space">&nbsp;</span></span></li>
            <li class="mt-6"><span></span><span>the right to data portability according to Article 20 GDPR.<span class="Apple-converted-space">&nbsp;</span></span></li>
        </ul>
        <ol start="2">
            <li class="mt-6"><span>Furthermore, you have the right to file a complaint with the Office for Personal Data Protection if you believe that your right to personal data protection has been violated.</span></li>
        </ol>

        <h2 class="text-2xl font-bold tracking-tight text-gray-900 mb-6 mt-8">VII. Conditions for the Security of Personal Data</h2>
        <ol>
            <li class="mt-6"><span>The controller declares that it has implemented all appropriate technical and organizational measures to secure personal data.</span></li>
            <li class="mt-6"><span>The controller has implemented technical measures to secure data storage and storage of personal data in paper form, especially contact details and photos.</span></li>
            <li class="mt-6"><span>The controller declares that only authorized persons have access to personal data.</span></li>
        </ol>
        <h2 class="text-2xl font-bold tracking-tight text-gray-900 mb-6 mt-8">VIII. Final Provisions</h2>
        <ol>
            <li class="mt-6"><span>By submitting an order from the online order form, you confirm that you are acquainted with the conditions of personal data protection and accept them in full.</span></li>
            <li class="mt-6"><span>By ticking the consent checkbox through the online form, you agree to these terms. By ticking the consent, you confirm that you are acquainted with the conditions of personal data protection and accept them in full.</span></li>
            <li class="mt-6"><span>The controller is entitled to change these terms. The new version of the personal data protection terms will be published on its website, and at the same time, the controller will send the new version of these terms to your email address provided to the controller.</span></li>
        </ol>
        <p class="mt-6"><span>These terms take effect on September 11, 2019.</span></p>
      </div>

    </div>
  </div>

  <MainFooter></MainFooter>
</template>


<script setup>
import { useSeoMeta } from '@vueuse/head'


useSeoMeta ({
	title: 'Privacy Policy | PetGotLost.com',
	description: "Is your dog or cat missing? We'll create a special alert campaign with photos on Facebook and Instagram. Reach thousands of locals in a few hours!",
	ogDescription: "Is your dog or cat missing? We'll create a special alert campaign with photos on Facebook and Instagram. Reach thousands of locals in a few hours!",
	ogTitle: 'Privacy Policy | PetGotLost.com',
	ogImage: 'https://petgotlost.com/og.jpg',
	twitterCard: 'summary_large_image',
  robots: "noindex"
});

</script>

<script>
import MainHeader from '../components/MainHeader.vue'
import MainFooter from '../components/MainFooter.vue'

export default {
  name: "PrivacyView", 
  components: {
    MainHeader,
    MainFooter
  }
}
</script>

