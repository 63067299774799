<template>
    <MainHeader />
    <div class="flex flex-row justify-center mt-10">
        <div class="max-w-2xl flex flex-col items-center text-center px-3">
            <div class="justify-center w-24">
                <img src="../assets/svg/cross.svg">
            </div>
            <div class="font-bold text-3xl mt-10">Payment failed</div>
            <p class="mt-5 text-stone-600">We were unable to process your payment. If you would like to pay by a bank transfer, please contact us on +44 7520 668505 or support@petgotlost.com. You can try again for the full payment.</p>
            <div class="mt-8">
                <BasicButton text="Try again" @click="retryCheckout" />
            </div>
        </div>
    </div>
    <teleport to="body">
        <PopupWindow v-if="error">
            <div class="block">
                <div class="mb-6 font-semibold">We apologize, but there's been an error. Please try again or contact us on +44 7520 668505.</div>
                <div class="flex">
                    <PetButton class="mr-3" text="Close" @click="error = false" />
                    <router-link to="/">
                        <PetButton text="Return home" />
                    </router-link>
                </div>
            </div>
        </PopupWindow>
    </teleport>
</template>

<script setup>
import { useSeoMeta } from '@vueuse/head'

useSeoMeta ({
	title: 'Payment failed | PetGotLost.com',
    description: 'Find Your Lost Pet Quickly! Start a FREE alert in just 60 seconds and reach locals on Facebook and Instagram. 1,000+ pets reunited across the UK.',
    ogDescription: 'Find Your Lost Pet Quickly! Start a FREE alert in just 60 seconds and reach locals on Facebook and Instagram. 1,000+ pets reunited across the UK.',
    ogTitle: 'Payment failed | PetGotLost.com',
    ogImage: 'https://petgotlost.com/og.jpg',
    twitterCard: 'summary_large_image',
    robots: "noindex"
});

</script>

<script>
import BasicButton from '../components/BasicButton.vue'
import MainHeader from '../components/MainHeader.vue'
import PopupWindow from '../components/PopupWindow.vue'

import axios from 'axios'

const url = process.env.SERVER_URL

export default {
    name: "FailureView", 
    components: {
        BasicButton,
        MainHeader,
        PopupWindow
    },
    data() {
        return {
            error: false
        }
    },
    methods: {
        async retryCheckout() {
            const order = this.$cookies.get('mazel-order-info')

            //get the checkout link
            const retryUrl = await axios.post(`${url}/api/payment`, order)
                .then(res => {
                    return res.data.data
                }).catch(err => {
                    this.error = true
                    console.log(err)
                })

            window.location.href = retryUrl
        },
        async sendEmail() {
            const uid = this.$cookies.get('mazel-pet-info').uid 
            const userEmail = this.$cookies.get('mazel-pet-info').userEmail
            const address = `${process.env.FRONTEND_URL}/retryPayment/${uid}`

            const payload = {
                address,
                userEmail
            }

            await axios.post(`${url}/api/email-fail`, payload)
                .catch(err => {
                    alert('failed sending email')
                    console.log(err)
                })
        }
    },
    mounted() {
        this.sendEmail()
    }
}
</script>


