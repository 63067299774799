import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LostView from '../views/LostView.vue'
import HowView from '../views/HowView.vue'
import PricingView from '../views/PricingView.vue'
import AboutView from '../views/AboutView.vue'
import PetView from '../views/PetView.vue'
import VopView from '../views/VopView.vue'
// import ReportView from '../views/ReportView.vue'
import RegistrationView from '../views/RegistrationView.vue'
import DescriptionView from '../views/DescriptionView.vue'
import TestemonialsView from '../views/TestemonialsView.vue'
import OrderView from '../views/OrderView.vue'
import PrivacyView from '../views/PrivacyView.vue'
import PageNotFound from '../views/PageNotFound.vue'
import SuccessView from '../views/SuccessView.vue'
import FailureView from '../views/FailureView.vue'
import RetryPaymentView from '../views/RetryPaymentView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/lost-pets',
    name: 'lost',
    component: LostView
  },
  {
    path: '/how-it-works',
    name: 'how',
    component: HowView
  },
  {
    path: '/pricing',
    name: 'pricing',
    component: PricingView
  },

  {
    path: '/customer-stories',
    name: 'testemonials',
    component: TestemonialsView
  },
  {
    path: '/about-us',
    name: 'about',
    component: AboutView
  },
  {
    path: '/register',
    name: 'registration',
    component: RegistrationView
  },
  {
    path: '/description',
    name: 'description',
    component: DescriptionView
  },
  {
    path: '/order',
    name: 'order',
    component: OrderView
  },
  {
    path: '/pet/:uid',
    name: 'pet',
    component: PetView,
    props: true
  },
  {
    path: '/:pathMatch(.*)*', 
    component: PageNotFound
  },
  {
    path: '/success',
    component: SuccessView
  },
  {
    path: '/failure',
    component: FailureView
  },
  {
    path: '/retryPayment/:uid',
    component: RetryPaymentView,
    props: true
  },
  {
    path: '/terms-and-conditions',
    component: VopView 
  },
  {
    path: '/privacy-policy',
    component: PrivacyView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior() {
      document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
  },
  routes
})

export default router
