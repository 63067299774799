<template>
    <nav class="flex items-center justify-between border-t border-gray-200 px-4 mt-16 sm:px-0">
        <div class="-mt-px flex w-0 flex-1">
            <button v-if="page !== 1" @click="paginate(page - 1)" class="inline-flex items-center border-t-2 border-transparent pr-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700">
                <ArrowLongLeftIcon class="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                Previous
            </button>
        </div>
        <div class="hidden md:-mt-px md:flex">
            <button @click="paginate(key.pageNum)" v-for="key in pages" class="inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700" :class="{'border-orange-500 text-orange-600': key.bold}" :key="key.pageNum">{{ key.pageNum }}</button>
        </div>
        <div class="-mt-px flex w-0 flex-1 justify-end">
            <button v-if="pageCount !== page" @click="paginate(page + 1)" class="inline-flex items-center border-t-2 border-transparent pl-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700">
                Next
                <ArrowLongRightIcon class="ml-3 h-5 w-5 text-gray-400" aria-hidden="true" />
            </button>
        </div>
    </nav>
</template>

<script setup>
import { ArrowLongLeftIcon, ArrowLongRightIcon } from '@heroicons/vue/20/solid'
</script>

<script>

export default {
    name: 'PetPaginator',
    props: {
        page: Number,
        pageCount: Number,
    },
    methods: {
        paginate(arg) {
            if(arg == 0) {
                arg = 1
            } else if(arg == -1 && arg > this.pageCount) {
                arg = this.pageCount
            }

            this.$emit("paginate", arg)
        },
    },
    computed: {
        pages() {
            let pages = []
            let base = 1
            let mod = 0

            const firstThree = this.page < 3 ? true : false
            const lastThree = this.page + 2 > this.pageCount ? true : false 

            if (this.buttonCount >= 5) {
                if (!firstThree) {
                    base = this.page
                }
                if (lastThree) {
                    base = this.pageCount - 4
                }
                if (!firstThree && !lastThree) {
                    mod = -2
                }
            }


            for(let i = 0; i < this.buttonCount; i++) { 

                const pageNum = base + mod + i
                const bold = this.page == pageNum

                const page = {
                    pageNum: pageNum,
                    bold: bold
                }

                pages.push(page)
            }

            return pages
        },
        buttonCount() {
            let buttonCount = 0

            if(this.pageCount > 5) {
                buttonCount = 5
            } else {
                buttonCount = this.pageCount
            }

            return buttonCount
        }
    }
}

</script>
