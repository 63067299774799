<template>
  <div class="safarifix bg-stone-900 py-24 relative overflow-hidden">
    <!-- bg-[#231b0b] -->
    <div class="mx-auto max-w-7xl px-6 lg:px-8 relative z-10">
      <div class="mx-auto max-w-4xl lg:text-center">
        <h2 class="text-base font-semibold leading-7 text-orange-400">Our Process</h2>
        <p class="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">How do the search campaigns work?</p>
        <p class="mt-6 text-lg leading-8 text-stone-300">Think of it just like the ad-posts you normally see on your social media. Only with a photo and description of your lost pet. We'll show it to all the people in the area of the loss. If anyone sees your pet, they will contact us immediately with the details.</p>
      </div>
      <div class="mx-auto mt-16 max-w-2xl sm:mt-20 lg:max-w-none">
        <dl class="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-4">
          <div v-for="feature in features" :key="feature.name" class="flex flex-col bg-white rounded-lg p-4">
            <div class="h-24 mb-4 flex items-center">
              <img :src="feature.icon" class=" max-w-[160px] max-h-24 " alt="" />
            </div>

            <dt class="flex items-center gap-x-3 text-xl font-semibold leading-7 text-stone-900">
              {{ feature.name }}
            </dt>
            <dd class="mt-4 flex flex-auto flex-col text-base leading-7 text-stone-600">
              <p class="flex-auto">{{ feature.description }}</p>
            </dd>
          </div>
        </dl>
      </div>

      <router-link to="/register" class="rounded-md mx-auto block w-fit bg-white px-4 py-3 mt-20 font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white">Report a Lost Pet</router-link>

    </div>

    <div class="absolute inset-0 block z-0 safarifix" aria-hidden="true">
      <img class="safarifix blur-sm block w-auto h-auto opacity-30 min-w-full min-h-full absolute top-0 left-0 object-cover" src="https://images.unsplash.com/photo-1623387641168-d9803ddd3f35?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80" alt="" />
    </div>
  </div>
</template>

<script setup>
// import { ArrowPathIcon, CloudArrowUpIcon, LockClosedIcon } from '@heroicons/vue/20/solid'
import hiw1 from '../assets/svg/hiw1.svg'
import hiw2 from '../assets/svg/hiw2.svg'
import hiw3 from '../assets/svg/hiw3.svg'
import hiw4 from '../assets/svg/hiw4.svg'




const features = [
  {
    name: '1. Registration',
    description:
      'It only takes a few minutes to complete the order. Our team will then plan your entire customized campaign right away.',
    icon: hiw1,
  },
  {
    name: '2. Launching the campaign',
    description:
      "Within hours, we'll begin notifying up to tens of thousands of people in the animal's location.",
    icon: hiw2,
  },
  {
    name: '3. Collecting clues',
    description:
      "We tailor the campaign based on the info from people who spotted the animal. This way, we can easily track the animal.",
    icon: hiw3,
  },
  {
    name: '4. Finding the pet',
    description:
      "On average, we are able to return 74% of pets safely home. Can we help you as well?",
    icon: hiw4,
  },
]
</script>

<script>
export default {
  name: 'HowItWorks'
}
</script>