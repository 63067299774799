<template>
	<header class="bg-white/90 backdrop-blur border-b border-stone-200 z-30 top-0">
		<nav class="mx-auto sm:flex max-w-7xl items-center justify-between p-4 sm:p-6 sm:py-4 lg:px-8" aria-label="Global">
			<div class="flex items-center gap-x-12">
				<router-link to="/" class="-m-1.5 p-1.5">
					<span class="sr-only">PetGotLost.com</span>
					<img class="h-8 w-auto" src="../assets/svg/logo.svg" alt="" />
				</router-link>
			</div>

			<div class="flex mt-5 sm:mt-0">
					<div class="mr-5 sm:mx-5 w-fit flex -space-x-4">
							<img class="w-7 h-7 sm:w-10 sm:h-10 border-2 border-white rounded-full" src="../assets/img/albert.jpg" alt="">
							<img class="w-7 h-7 sm:w-10 sm:h-10 border-2 border-white rounded-full" src="../assets/img/anicka.jpg" alt="">
							<div class="relative">
									<img class="w-7 h-7 sm:w-10 sm:h-10 border-2 border-white rounded-full" src="../assets/img/jirka.jpg" alt="">
									<span class="top-0 left-5 sm:left-7 absolute  w-3.5 h-3.5 bg-green-400 border-2 border-white rounded-full"></span>
							</div>
					</div>
					<a href="tel:+447520668505" class="sm:text-lg font-bold text-stone-900 tracking-normal flex items-center">
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-5 h-5 mr-2 inline-block">
									<path fill-rule="evenodd" d="M1.5 4.5a3 3 0 013-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 01-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 006.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 011.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 01-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5z" clip-rule="evenodd" />
							</svg>
							+44 7520 668505
					</a>
			</div>
		</nav>
		<Dialog as="div" class="lg:hidden" @close="mobileMenuOpen = false" :open="mobileMenuOpen">
			<div class="fixed inset-0" />
			<DialogPanel class="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-4 py-4 sm:px-6 sm:py-6 sm:max-w-sm sm:ring-1 sm:ring-stone-900/10 z-50">
				<div class="flex items-center justify-between">
					<router-link to="/" class="-m-1.5 p-1.5">
						<span class="sr-only">PetGotLost.com</span>
						<img class="h-8 w-auto" src="../assets/svg/logo.svg" alt="" />
					</router-link>
					<button type="button" class="-m-2.5 rounded-md p-2.5 text-stone-700" @click="mobileMenuOpen = false">
						<span class="sr-only">Close menu</span>
						<XMarkIcon class="h-6 w-6" aria-hidden="true" />
					</button>
				</div>
				<div class="mt-6 flow-root">
					<div class="-my-6 divide-y divide-stone-500/10">
						<div class="space-y-2 py-6">
							<router-link v-for="item in navigation" :key="item.name" :to="item.href" class="-mx-3 block rounded-lg px-2 py-2 text-base font-semibold leading-7 text-stone-900 hover:bg-stone-50" exact-active-class="bg-stone-200 hover:bg-stone-200">{{ item.name }}</router-link>
						</div>
						<div class="py-6">
							<router-link to="/register" class="text-md font-semibold leading-6 text-white px-6 py-4 bg-gradient-to-r rounded-md from-[#FF9A45] hover:from-[#E68B3E] to-[#FF405B] hover:to-[#E53952] transition-all hover:drop-shadow-md drop-shadow-xl" >Report a Lost Pet</router-link>
						</div>
					</div>
				</div>
			</DialogPanel>
		</Dialog>
	</header>
</template>



<script setup>
	import { ref } from 'vue'
	import { Dialog, DialogPanel } from '@headlessui/vue'
	import { XMarkIcon } from '@heroicons/vue/24/outline'

	const navigation = [
	{ name: 'Lost Pets', href: '/lost-pets' },
	{ name: 'How It Works', href: '/how-it-works' },
	{ name: 'Pricing', href: '/pricing' },
	{ name: 'Customer Stories', href: '/customer-stories' },
	{ name: 'About Us', href: '/about-us' },
	]

	const mobileMenuOpen = ref(false)
</script>

<script>

export default {
	name: 'BareboneHeader',
}
</script>