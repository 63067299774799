<template>

	<TransitionRoot as="template" :show="scammerOpen">
    <Dialog as="div" class="relative z-50 " @close="scammerOpen = false">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-stone-500 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-50 w-screen overflow-y-auto">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <DialogPanel class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl sm:p-6">
              <div class="sm:flex sm:items-start">
                <div class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                  <ExclamationTriangleIcon class="h-6 w-6 text-red-600" aria-hidden="true" />
                </div>
                <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                  <DialogTitle as="h3" class="text-base font-semibold leading-6 text-stone-900">SCAM WARNING</DialogTitle>
                  <div class="mt-2">
                    <p class="text-sm text-stone-700 mb-3">We've received reports of fraudulent calls targeting owners of missing pets listed on PetGotLost.com. It's vital to remain vigilant as these scammers can be highly convincing.</p>
                    <p class="text-sm text-stone-700 mb-3">The caller typically poses as a representative from a veterinary clinic, the RSPCA, or a rescue centre, falsely claiming that immediate payment is required for urgent surgery needed by the pet. Alternatively, they may request a small 'release fee' of around £5 for the pet to be returned to its owner.</p>
                    <p class="text-sm font-medium text-stone-700 mb-3">Please don't fall victim to this scam.</p>
                    <p class="text-sm font-bold mb-1 text-stone-900">Our advice:</p>
                    <p class="text-sm text-stone-700">If you're uncertain about the authenticity of a call regarding your missing pet, take note of the vet or rescue centre's name and conduct an online search for their contact details. Reach out to them directly. Legitimate organisations will not object to this verification process. Do not use the contact number provided by the scammers. Disconnect the call immediately! If you're using a landline, ensure the line is clear after hanging up to prevent further communication from the scammers.</p>
                  </div>
                </div>
              </div>
              <div class="mt-5 sm:ml-10 sm:mt-4 sm:flex sm:pl-4">
                <button type="button" class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-stone-900 shadow-sm ring-1 ring-inset ring-stone-300 hover:bg-stone-50 sm:mt-0 sm:w-auto" @click="scammerOpen = false" ref="cancelButtonRef">Close warning</button>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>



	<div class="bg-orange-200 flex justify-center items-center py-2">
		<p class="hidden sm:block font-normal text-stone-900">Our team on the phone will help you with everything</p>
		<div class="mr-5 sm:mx-5 w-fit flex -space-x-4">
			<img class="w-7 h-7 sm:w-10 sm:h-10 border-2 border-white rounded-full" src="../assets/img/albert.jpg" alt="">
			<img class="w-7 h-7 sm:w-10 sm:h-10 border-2 border-white rounded-full" src="../assets/img/anicka.jpg" alt="">
			<div class="relative">
				<img class="w-7 h-7 sm:w-10 sm:h-10 border-2 border-white rounded-full" src="../assets/img/jirka.jpg" alt="">
				<span class="top-0 left-5 sm:left-7 absolute  w-3.5 h-3.5 bg-green-400 border-2 border-white rounded-full"></span>
			</div>
		</div>
		<a href="tel:+447520668505" class="sm:text-lg font-bold text-stone-900 tracking-normal flex items-center">

			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-5 h-5 mr-2 inline-block">
				<path fill-rule="evenodd" d="M1.5 4.5a3 3 0 013-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 01-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 006.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 011.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 01-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5z" clip-rule="evenodd" />
			</svg>

			+44 7520 668505

		</a>
	</div>

	<div class="bg-orange-300 w-full pt-1 pb-1">
		<p class="text-orange-900 font-medium text-sm text-center">🚨 WARNING: Beware of scammers. <button @click="scammerOpen = true" class="text-orange-900 underline">Click here to read this important message</button> 🚨</p>
	</div>

	
	<header class="bg-white/90 backdrop-blur border-b border-stone-200 z-30 sticky top-0">
		<nav class="mx-auto flex max-w-7xl items-center justify-between p-4 sm:p-6 lg:px-8" aria-label="Global">
			<div class="flex items-center gap-x-12">
				<router-link to="/" class="-m-1.5 p-1.5">
					<span class="sr-only">PetGotLost.com</span>
					<img class="h-8 w-auto" src="../assets/svg/logo.svg" alt="" />
				</router-link>
				<div class="hidden lg:flex lg:gap-x-2 xl:gap-x-8">
				<router-link v-for="item in navigation" :key="item.name" :to="item.href" class="text-sm xl:text-md font-semibold leading-6 text-stone-900 px-2 py-1 hover:bg-stone-100 rounded-lg" exact-active-class="bg-stone-200 hover:bg-stone-200">{{ item.name }}</router-link>
				</div>
			</div>
			<div class="flex lg:hidden">
				<button type="button" class="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-stone-700" @click="mobileMenuOpen = true">
				<span class="sr-only">Open main menu</span>
				<Bars3Icon class="h-6 w-6" aria-hidden="true" />
				</button>
			</div>
			<div class="hidden lg:flex">
				<router-link to="/register" class="text-md font-semibold leading-6 text-white px-4 py-2 bg-gradient-to-r rounded-md from-[#FF9A45] hover:from-[#E68B3E] to-[#FF405B] hover:to-[#E53952] transition-all hover:drop-shadow-md drop-shadow-xl">Report a Lost Pet <span aria-hidden="true">&rarr;</span></router-link>
			</div>
		</nav>
		<Dialog as="div" class="lg:hidden" @close="mobileMenuOpen = false" :open="mobileMenuOpen">
			<div class="fixed inset-0" />
			<DialogPanel class="fixed inset-y-0 right-0 w-full overflow-y-auto bg-white px-4 py-4 sm:px-6 sm:py-6 sm:max-w-sm sm:ring-1 sm:ring-stone-900/10 z-50">
				<div class="flex items-center justify-between">
					<router-link to="/" class="-m-1.5 p-1.5">
						<span class="sr-only">PetGotLost.com</span>
						<img class="h-8 w-auto" src="../assets/svg/logo.svg" alt="" />
					</router-link>
					<button type="button" class="-m-2.5 rounded-md p-2.5 text-stone-700" @click="mobileMenuOpen = false">
						<span class="sr-only">Close menu</span>
						<XMarkIcon class="h-6 w-6" aria-hidden="true" />
					</button>
				</div>
				<div class="mt-6 flow-root">
					<div class="-my-6 divide-y divide-stone-500/10">
						<div class="space-y-2 py-6">
							<router-link v-for="item in navigation" :key="item.name" :to="item.href" class="-mx-3 block rounded-lg px-2 py-2 text-base font-semibold leading-7 text-stone-900 hover:bg-stone-50" exact-active-class="bg-stone-200 hover:bg-stone-200">{{ item.name }}</router-link>
						</div>
						<div class="py-6">
							<router-link to="/register" class="text-md font-semibold leading-6 text-white px-6 py-4 bg-gradient-to-r rounded-md from-[#FF9A45] hover:from-[#E68B3E] to-[#FF405B] hover:to-[#E53952] transition-all hover:drop-shadow-md drop-shadow-xl" >Report a Lost Pet</router-link>
						</div>
					</div>
				</div>
			</DialogPanel>
		</Dialog>


		<component :is="'script'" type="application/ld+json">
			{
				"@context": "https://schema.org",
				"@type": "Product",
				"name": "PetGotLost.com",
				"brand":"petgotlost.com",
				"image":"https://petgotlost.com/og.jpg",
				"description": "Find Your Lost Pet Quickly! Start a FREE alert in just 60 seconds and reach locals on Facebook and Instagram. 1,000+ pets reunited across the UK.",
				"aggregateRating": {
					"@type":"AggregateRating",
					"bestRating": "5",
					"ratingCount": 547,
					"ratingValue": 4.9,
					"worstRating": "1"
				}
			}
		</component>


	</header>

	
</template>



<script setup>
	import { ref } from 'vue'
	import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
	import { Bars3Icon, XMarkIcon, ExclamationTriangleIcon } from '@heroicons/vue/24/outline'	


	const navigation = [
	{ name: 'Lost Pets', href: '/lost-pets' },
	{ name: 'How It Works', href: '/how-it-works' },
	{ name: 'Pricing', href: '/pricing' },
	{ name: 'Customer Stories', href: '/customer-stories' },
	{ name: 'About Us', href: '/about-us' },
	]

	const mobileMenuOpen = ref(false)
	const scammerOpen = ref(false)
</script>

<script>

export default {
	name: 'MainHeader',
}
</script>